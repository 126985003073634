import { Components, LayoutConfiguration } from '@livekatsomo/models';
import Dialog from '@mui/material/Dialog';
import LayoutEditor from './LayoutEditor';
import { FullScreenDialogTransition } from '../transitions/FullScreenDialogTransition';

export interface LayoutEditorDialogProps {
  components: Components;
  open: boolean;
  title: string;
  layout: LayoutConfiguration;
  onClose: () => void;
  onUpdateEventLayout: (layout: LayoutConfiguration) => Promise<void>;
}

export function LayoutEditorDialog({
  components,
  layout,
  open,
  title,
  onClose,
  onUpdateEventLayout,
}: LayoutEditorDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      TransitionComponent={FullScreenDialogTransition}
    >
      <LayoutEditor
        title={title}
        currentLayout={layout}
        components={components}
        onSubmit={onUpdateEventLayout}
        onClose={onClose}
      />
    </Dialog>
  );
}
