/**
 * @file This file exports the LayoutEditor component, which is used to edit a layout configuration.
 * It allows the user to add, edit, and delete collections, areas, and components within the layout.
 * The component also provides a preview of the layout as it is being edited.
 */
import { UniqueIdentifier } from '@dnd-kit/core';
import {
  AreaConfiguration,
  CollectionConfiguration,
  ComponentConfiguration,
  Components,
  LayoutConfiguration,
  RenderingConfiguration,
  layoutConfigurationSchema,
} from '@livekatsomo/models';
import { DebugDetails } from '@livekatsomo/web/ui-components/debug-details';
import Add from '@mui/icons-material/Add';
import DialogContent from '@mui/material/DialogContent';
import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';
import deepEqual from 'deep-equal';
import { useTranslation } from 'next-i18next';
import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { LayoutContainer } from '../layout-containers/LayoutContainer';
import {
  AddFormSchema,
  LayoutEditorAddItemDialog,
} from '../layout-containers/LayoutEditorAddItemDialog';
import { LayoutWithCenterSlider } from '../layout-containers/LayoutWithCenterSlider';
import { EditContext } from '../layout-rendering/EditContext';
import { RenderEditLayout } from '../layout-rendering/RenderEditLayout';
import { LayoutEditorAppBar } from './LayoutEditorAppBar';
import { LayoutEditorDrawer } from './LayoutEditorDrawer';
import { z } from 'zod';

/**
 * Configuration object for custom rendering of the layout editor.
 * @property layoutWithCenterSlider - The component to use for rendering the layout editor with a center slider.
 * @property layout - The component to use for rendering the layout editor.
 */
const customRenderingConfiguration: RenderingConfiguration = {
  layoutWithCenterSlider: LayoutWithCenterSlider,
  layout: LayoutContainer,
};

/**
 * The width of the drawer in pixels.
 */
export const drawerWidth = 340;

/**
 * A styled div component that serves as the header for a drawer.
 * @param theme - The theme object containing styles for the component.
 * @param theme.spacing - The spacing value for the component's padding.
 * @param theme.mixins.toolbar - The mixin object containing styles for the component's toolbar.
 * @returns  - The JSX element representing the DrawerHeader component.
 */
export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

/**
 * Main component for the LayoutEditor.
 * @param open - Whether the drawer is open or not.
 * @returns  - The Main component.
 */
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  position: 'relative',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: 0,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  }),
}));

/**
 * Props for the LayoutEditor component.
 */
export interface LayoutEditorProps {
  /**
   * The title of the layout editor.
   */
  title?: string;
  /**
   * The current layout configuration.
   */
  currentLayout: LayoutConfiguration;
  /**
   * The available components for the layout editor.
   */
  components: Components;
  /**
   * The function to be called when the layout editor is closed.
   */
  onClose: () => void;
  /**
   * The function to be called when the layout is submitted.
   * @param layout - The updated layout configuration.
   */
  onSubmit: (layout: LayoutConfiguration) => Promise<void>;
}

/**
 * A component that allows the user to edit a layout configuration.
 * @param props - The component props.
 * @returns  - The rendered LayoutEditor component.
 */
export function LayoutEditor({
  title,
  currentLayout,
  components,
  onSubmit,
  onClose,
}: LayoutEditorProps) {
  const { t } = useTranslation();
  const [layoutConfiguration, setValue] = useState(() =>
    structuredClone(currentLayout),
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [open, setOpen] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [clonedConfig, setClonedConfig] = useState<LayoutConfiguration | null>(
    null,
  );

  const isDirty = !deepEqual(layoutConfiguration, currentLayout, {
    strict: true,
  });

  const [editItem, setEditItem] = useState<{
    id: string;
    configuration:
      | CollectionConfiguration
      | AreaConfiguration
      | ComponentConfiguration;
  } | null>(null);
  const drawerOpen = Boolean(editItem) || open;
  const [addItemCollectionId, setAddItemCollectionId] = useState<string | null>(
    null,
  );

  const availableComponents = Object.keys(components).filter(
    (value: string) => !layoutConfiguration.collections[value],
  );

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    handleCancel();
  };

  const handleEditItem = useCallback(
    (
      id: string,
      value:
        | Partial<CollectionConfiguration>
        | Partial<ComponentConfiguration>
        | Partial<AreaConfiguration>,
    ) => {
      console.log('handleEditItem', { id, value });

      const newConfig = { ...layoutConfiguration };
      newConfig.collections[id] = {
        ...newConfig.collections[id],
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ...(value as any),
      };
      if (newConfig.collections[id].type !== 'component') {
        (
          newConfig.collections[id] as
            | CollectionConfiguration
            | AreaConfiguration
        ).maxWidth = 'maxWidth' in value ? value.maxWidth : undefined;
      }
      setValue({
        ...newConfig,
      });
      setAddItemCollectionId(null);
    },
    [layoutConfiguration, setValue],
  );

  const handleCancel = useCallback(() => {
    console.log(editItem);
    setAddItemCollectionId(null);
    setEditItem(null);

    if (!editItem) {
      setValue(layoutConfiguration);
      return;
    }
    setValue({
      ...layoutConfiguration,
      collections: {
        ...layoutConfiguration.collections,
        [editItem.id]: editItem.configuration,
      },
    });
  }, [layoutConfiguration, editItem, setValue]);

  const handleDeleteConfiguration = useCallback(
    (id: string) => {
      const newConfig = { ...layoutConfiguration };

      function deleteConfiguration(id: string) {
        const configuration = newConfig.collections[id];
        if (configuration.type !== 'component') {
          // If the item is a container, delete all items in the container
          const items = configuration.items;
          items.forEach((item) => {
            deleteConfiguration(item);
          });
        }
        delete newConfig.collections[id];
      }

      deleteConfiguration(id);

      if (activeId === id) {
        setActiveId(null);
      }
      // Filter id from all collections where id is in items
      Object.keys(newConfig.collections).forEach((key) => {
        const collection = newConfig.collections[key];
        if (collection.type !== 'component') {
          collection.items = collection.items.filter((i) => i !== id);
        }
      });

      setValue(newConfig);
    },
    [activeId, layoutConfiguration],
  );

  const closeAddItemDialog = () => setAddItemCollectionId(null);

  const handleEditConfiguration = useCallback((id: string) => {
    console.log('handleEditConfiguration', { id });
  }, []);

  const handleAddItem = useCallback(
    (collectionId: string, values: z.infer<typeof AddFormSchema>) => {
      const newConfig = { ...layoutConfiguration };
      const collection = newConfig.collections[collectionId];
      if (collection.type === 'component') {
        throw new Error('Cannot add item to component');
      }
      if (values.type === 'component') {
        newConfig.collections[values.componentName] = {
          type: 'component',
          componentName: values.componentName,
          componentContainer: 'component',
        };
        collection.items.push(values.componentName);
        setValue(newConfig);
      } else {
        const newCollectionId = uuid();
        newConfig.collections[newCollectionId] = { ...values, items: [] };
        collection.items.push(newCollectionId);
        setValue(newConfig);
      }
      setAddItemCollectionId(null);
    },
    [layoutConfiguration, setValue],
  );

  const handleShowEditItem = useCallback(
    (id: string) => {
      setClonedConfig(layoutConfiguration);
      setEditItem(
        id in layoutConfiguration.collections
          ? { id, configuration: { ...layoutConfiguration.collections[id] } }
          : null,
      );
    },
    [layoutConfiguration],
  );

  const handleReset = useCallback(() => {
    setValue(structuredClone(currentLayout));
    setEditItem(null);
  }, [currentLayout, setValue]);

  const handleSubmit = useCallback(async () => {
    console.log('handleSubmit');
    setIsSubmitting(true);
    const cleanedValues = layoutConfigurationSchema.parse(layoutConfiguration);
    console.log(cleanedValues);
    try {
      await onSubmit(cleanedValues);
    } catch (e) {
      console.error(e);
    }
    setIsSubmitting(false);
  }, [layoutConfiguration, onSubmit]);

  return (
    <EditContext.Provider
      value={{
        activeId,
        deleteConfiguration: handleDeleteConfiguration,
        editConfiguration: handleEditConfiguration,
        showAddDialog: (collectionId) => setAddItemCollectionId(collectionId),
        showEdit: handleShowEditItem,
      }}
    >
      <Main open={drawerOpen}>
        <LayoutEditorAppBar
          open={open}
          onToggleDrawer={handleDrawerOpen}
          onClose={onClose}
          title={title || t('Layout editor')}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onReset={handleReset}
          onSubmit={handleSubmit}
        />
        <DialogContent>
          <DebugDetails data={{ layoutConfiguration, isDirty }} />
          <RenderEditLayout
            id="eventLayout"
            components={components}
            renderingConfiguration={customRenderingConfiguration}
            layoutConfiguration={layoutConfiguration}
            setConfig={setValue}
            activeId={activeId}
            setActiveId={setActiveId}
            clonedConfig={clonedConfig}
            setClonedConfig={setClonedConfig}
          />
          <LayoutEditorAddItemDialog
            components={availableComponents}
            collectionId={addItemCollectionId}
            onSubmit={handleAddItem}
            onClose={closeAddItemDialog}
          />
          <Fab
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            color="primary"
            aria-label={t('add') || 'add'}
            onClick={() => setAddItemCollectionId('baseLayout')}
          >
            <Add />
          </Fab>
          <LayoutEditorDrawer
            open={drawerOpen}
            editItem={editItem}
            isDirty={isDirty}
            isSubmitting={isSubmitting}
            onSetEditItem={setEditItem}
            onDrawerClose={handleDrawerClose}
            onEditItem={handleEditItem}
            onCancel={handleCancel}
            onEditBaseLayout={() => handleShowEditItem('baseLayout')}
            onReset={handleReset}
            onSubmit={handleSubmit}
            onClose={onClose}
          />
        </DialogContent>
      </Main>
    </EditContext.Provider>
  );
}

export default LayoutEditor;
