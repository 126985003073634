import { UniqueIdentifier } from '@dnd-kit/core';
import { LayoutConfiguration } from '@livekatsomo/models';

/**
 * Determines whether the specified active ID in the given layout configuration is a container or not.
 * @param config The layout configuration to check.
 * @param activeId The ID of the active element to check.
 * @returns A boolean indicating whether the specified active ID is a container or not.
 */
export function isContainer(
  config: LayoutConfiguration,
  activeId: UniqueIdentifier,
) {
  return config.collections[activeId as string].type !== 'component';
}
