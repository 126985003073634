import { DragOverEvent } from '@dnd-kit/core';
import { LayoutConfiguration } from '@livekatsomo/models';
import { TRASH_ID } from './RenderEditLayout';

/**
 * Handles the logic for when a draggable item is dragged over a droppable container.
 * @param collections - The layout configuration collections.
 * @param setConfig - The state setter for the layout configuration.
 * @param recentlyMovedToNewContainer - A mutable ref object indicating whether an item was recently moved to a new container.
 * @returns A function to handle the drag over event.
 */
export function dragOver(
  collections: LayoutConfiguration['collections'],
  setConfig: React.Dispatch<React.SetStateAction<LayoutConfiguration>>,
  recentlyMovedToNewContainer: React.MutableRefObject<boolean>,
): ({ active, over }: DragOverEvent) => void {
  return function dragOver({ active, over }: DragOverEvent) {
    const overId = over?.id;
    const activeContainer = collections[active.id];
    const sourceContainer = active.data.current?.sortable.containerId;
    if (over === null || overId == null || overId === TRASH_ID) {
      return;
    }
    if (overId === active.id) {
      return;
    }

    const destinationContainer = over.data.current?.sortable.containerId;
    if (active.id === destinationContainer) {
      return;
    }

    if (sourceContainer === destinationContainer) {
      return;
    }

    // Check that the over container is not a child of the active container
    if (activeContainer.type !== 'component') {
      const activeContainerItems = activeContainer.items;
      if (activeContainerItems.includes(overId as string)) {
        return;
      }
      if (activeContainerItems.includes(destinationContainer)) {
        return;
      }
    }

    setConfig((prevConfig) => {
      const destinationConfig = prevConfig.collections[destinationContainer];
      const sourceConfig = prevConfig.collections[sourceContainer];

      // Return early if there is no destination containers
      if (!destinationConfig) {
        return prevConfig;
      }

      // Return early if there is no source containers
      if (!sourceConfig) {
        return prevConfig;
      }

      // Return early if the destination container is full
      if (
        destinationConfig.type !== 'component' &&
        destinationConfig.maxChildCount &&
        destinationConfig.items.length >= destinationConfig.maxChildCount
      ) {
        return prevConfig;
      }

      // Return early if the source or destination containers are components
      if (
        destinationConfig.type !== 'collection' ||
        sourceConfig.type !== 'collection'
      ) {
        return prevConfig;
      }

      recentlyMovedToNewContainer.current = true;

      const isRow =
        destinationConfig.sx?.display === 'flex' &&
        !destinationConfig.sx?.flexDirection?.includes('column');

      const isBeforeOverItem =
        over &&
        active.rect.current.translated &&
        active.rect.current.translated[isRow ? 'left' : 'top'] +
          active.rect.current.translated[isRow ? 'width' : 'height'] / 2 >
          over.rect[isRow ? 'left' : 'top'] +
            over.rect[isRow ? 'width' : 'height'] / 2;

      const modifier = isBeforeOverItem ? 1 : 0;
      const destinationItems = destinationConfig.items;
      const destinationIndex = destinationItems.indexOf(overId as string);
      const newIndex =
        destinationIndex >= 0
          ? destinationIndex + modifier
          : isBeforeOverItem
          ? 0
          : destinationItems.length;
      const sourceItems = sourceConfig.items;
      const newSourceItems = sourceItems.filter((id) => id !== active.id);
      const activeIndex = sourceItems.indexOf(active.id as string);
      const activeItem = sourceItems[activeIndex];
      const newDestinationItems = [
        ...destinationItems.slice(0, newIndex),
        activeItem,
        ...destinationItems.slice(newIndex, destinationItems.length),
      ];
      return {
        ...prevConfig,
        collections: {
          ...prevConfig.collections,
          [sourceContainer]: {
            ...sourceConfig,
            items: newSourceItems,
          },
          [destinationContainer]: {
            ...destinationConfig,
            items: newDestinationItems,
          },
        },
      };
    });
  };
}
