import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import LoadingButton from '@mui/lab/LoadingButton';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

/**
 * Props for the LayoutEditorAppBar component.
 */
export interface LayoutEditorAppBarProps {
  /**
   * Whether the drawer is currently open or not.
   */
  open: boolean;
  /**
   * The title to display in the app bar.
   */
  title: string;
  /**
   * Boolean indicating whether the layout is dirty.
   */
  isDirty: boolean;
  /**
   * Boolean indicating whether the form is submitting.
   */
  isSubmitting: boolean;
  /**
   * Function to close dialog.
   */
  onClose: () => void;
  /**
   * Callback function to reset the layout.
   */
  onReset: () => void;
  /**
   * Callback function to submit the layout.
   */
  onSubmit: () => void;
  /**
   * Function to handle opening the drawer.
   */
  onToggleDrawer: () => void;
}

/**
 * A component that renders the app bar for the layout editor.
 * @returns - The JSX element representing the app bar.
 */
export function LayoutEditorAppBar({
  open,
  title,
  isDirty,
  isSubmitting,
  onClose,
  onReset,
  onSubmit,
  onToggleDrawer,
}: LayoutEditorAppBarProps) {
  const { t } = useTranslation();
  return (
    <AppBar position="sticky" color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label={t('open drawer') || 'open drawer'}
          onClick={onToggleDrawer}
          edge="start"
          sx={{
            mr: 2,
            ...(open && {
              display: 'none',
            }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h6"
          noWrap
          component="div"
          sx={{
            flex: 1,
          }}
        >
          {title}
        </Typography>
        <Button color="inherit" disabled={!isDirty} onClick={onReset}>
          {t('Reset')}
        </Button>
        <LoadingButton
          disabled={!isDirty}
          loading={isSubmitting}
          color="inherit"
          onClick={onSubmit}
        >
          {t('Save')}
        </LoadingButton>
      </Toolbar>
    </AppBar>
  );
}

export default LayoutEditorAppBar;
