import Box from '@mui/material/Box';

/**
 * Props for the ContentAreaContainer component.
 */
export interface ContentAreaContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * A layout component that renders its children in a flexible column layout with vertical spacing.
 *
 * @param children - The child elements to be rendered within the content area.
 * @returns A JSX element representing the content area.
 */
export function ContentArea({ children }: ContentAreaContainerProps) {
  if (!children) return null;
  return (
    <Box
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          gap: 2,
          minWidth: 0,
          minHeight: 0,
        },
      ]}
    >
      {children}
    </Box>
  );
}

export default ContentArea;
