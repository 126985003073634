import {
  AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable';

/**
 * Animates layout changes for a sortable component.
 * @param args - The arguments for the animation.
 * @returns The animated layout changes.
 */
export const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });
