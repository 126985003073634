import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { ChangeEvent } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';

/**
 * A styled input component with a fixed width of 42px.
 */
const Input = styled(MuiInput)`
  width: 42px;
`;

/**
 * Props for the InputSliderField component.
 * @typeParam FormValues The type of the form values object.
 */
export interface InputSliderFieldProps<FormValues extends FieldValues> {
  name: Path<FormValues>;
  label: string;
  icon: React.ReactNode;
  min?: number;
  max?: number;
  defaultValue?: number;
  control: Control<FormValues>;
}

/**
 * A component that renders a slider input field with an associated label and icon.
 *
 * @typeParam FormValues - The type of the form values object that contains this field's value.
 *
 * @param props - The component props.
 * @param props.name - The name of the field, which should correspond to a key in the form values object.
 * @param props.label - The label to display above the slider input.
 * @param {React.ReactNode} props.icon - The icon to display to the left of the slider input.
 * @param {number} [props.min=0] - The minimum value of the slider.
 * @param {number} [props.max=100] - The maximum value of the slider.
 * @param {number} [props.defaultValue=0] - The default value of the slider.
 * @param {import('react-hook-form').Control<FormValues>} props.control - The `Control` object from `react-hook-form` that manages the form values.
 *
 * @returns The rendered `InputSliderField` component.
 */
export default function InputSliderField<FormValues extends FieldValues>({
  name,
  label,
  icon,
  defaultValue = 0,
  min = 0,
  max = 100,
  control,
}: InputSliderFieldProps<FormValues>) {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController<FormValues>({ name, control });

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    onChange(newValue, { shouldValidate: true, shouldDirty: true });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value === '' ? '' : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value > 100) {
      onChange(100);
    }
    onBlur();
  };

  return (
    <Box sx={{ width: 250 }}>
      <Typography id="input-slider" gutterBottom>
        {label}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>{icon}</Grid>
        <Grid item xs>
          <Slider
            value={typeof value === 'number' ? value : defaultValue}
            onChange={handleSliderChange}
            aria-labelledby="input-slider"
            min={min}
            max={max}
          />
        </Grid>
        <Grid item>
          <Input
            value={value || defaultValue}
            size="small"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              step: 1,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
            }}
          />
        </Grid>
      </Grid>
      <Typography variant="caption" color="error">
        {error?.message}
      </Typography>
    </Box>
  );
}
