import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  ComponentConfiguration,
  LayoutConfiguration,
  RenderingConfiguration,
} from '@livekatsomo/models';
import Box from '@mui/material/Box';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useEffect, useState } from 'react';
import { LayoutMenuButton } from '../layout-containers/LayoutMenuButton';
import { LayoutOverlay } from '../layout-containers/LayoutOverlay';
import {
  RenderComponent,
  RenderComponentProps,
} from '../layout-rendering/RenderComponent';
import { animateLayoutChanges } from './animateLayoutChanges';

/**
 * A hook that returns a boolean indicating whether the component is mounted or not.
 * @returns {boolean} - A boolean indicating whether the component is mounted or not.
 */
function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

/**
 * Props for the SortableComponentWrapper component.
 */
export type SortableComponentWrapperProps = RenderComponentProps & {
  /**
   * The ID of the component.
   */
  id: string;
  /**
   * The layout configuration for the component.
   */
  layoutConfiguration: LayoutConfiguration;
  /**
   * The rendering configuration for the component.
   */
  renderingConfiguration: RenderingConfiguration;
  /**
   * The component container to use.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComponentContainer: OverridableComponent<any> | undefined;
} & React.HTMLAttributes<HTMLDivElement> &
  ComponentConfiguration;

/**
 * A wrapper component for sortable components in a layout.
 * @param id - The unique identifier for the component.
 * @param children - The child components to be rendered within the wrapper.
 * @param ComponentContainer - The container component for the child components.
 * @param configuration - The configuration object for the component.
 * @param layoutConfiguration - The layout configuration object for the component.
 * @param renderingConfiguration - The rendering configuration object for the component.
 * @param boxed - A boolean indicating whether the component should be boxed.
 * @param square - A boolean indicating whether the component should be square.
 * @returns A React component that wraps sortable components in a layout.
 */
export function SortableComponentWrapper({
  id,
  children,
  ComponentContainer,
  configuration,
  layoutConfiguration,
  renderingConfiguration,
  boxed,
  square,
  ...props
}: SortableComponentWrapperProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id,
    animateLayoutChanges,
    data: { componentId: id, configuration },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <RenderComponent
      className="render-component"
      configuration={configuration}
      renderingConfiguration={renderingConfiguration}
      {...props}
    >
      <Box
        ref={setNodeRef}
        id={id}
        {...attributes}
        style={style}
        className="sortable-component-wrapper"
        sx={{
          '@keyframes fadeIn': {
            '0%': {
              opacity: 0,
            },
            '100%': {
              opacity: 1,
            },
          },

          // Add appear transition
          transition: 'opacity 3s',
          opacity: isDragging ? 0.5 : 1,
          ...(mountedWhileDragging && {
            // Fade in
            animation: 'fadeIn 0.3s',
          }),
          position: 'relative',
          '&:hover': {
            '& > .layout-overlay,& > .layout-menu-button': {
              opacity: 1,
              visibility: 'visible',
            },
            '& > .layout-menu-button': {
              opacity: 1,
              visibility: 'visible',
              pointerEvents: 'all',
            },
          },
        }}
      >
        {ComponentContainer ? (
          <ComponentContainer
            sx={{
              pointerEvents: 'none',
            }}
            className="component-container"
            boxed={boxed}
            square={square}
            {...props}
          >
            {children}
          </ComponentContainer>
        ) : (
          <Box
            sx={{
              pointerEvents: 'none',
            }}
          >
            {children}
          </Box>
        )}
        <LayoutOverlay type={configuration.type} />
        <LayoutMenuButton
          type={configuration.type}
          id={id}
          setActivatorNodeRef={setActivatorNodeRef}
          listeners={listeners}
        />
      </Box>
    </RenderComponent>
  );
}

export default SortableComponentWrapper;
