/**
 * @file This file exports a React component that renders a layout with a center slider.
 * @module LayoutWithCenterSlider
 */

import { LayoutProps } from '@livekatsomo/models';
import Box, { BoxProps } from '@mui/material/Box';
import { ContainerProps } from '@mui/material/Container';
import { PaperProps } from '@mui/material/Paper';
import { createSvgIcon } from '@mui/material/utils';
import { ReactNode, cloneElement, forwardRef, useState } from 'react';
import { CenterSlider } from './CenterSlider';

/**
 * Props for the `LayoutWithCenterSlider` component.
 */
export type LayoutWithCenterSliderProps = (
  | BoxProps
  | PaperProps
  | ContainerProps
) & {
  /**
   * An array of strings representing the items to be displayed in the layout.
   */
  items: string[];
} & LayoutProps & {
    /**
     * The aspect ratio of the layout.
     */
    aspectRatio?: '16/9' | '24/9' | '32/9';
    /**
     * The children to be rendered in the layout.
     */
    children: ReactNode | [ReactNode, ReactNode];
  };

/**
 * A React component that renders a layout with a center slider.
 *
 * @param {LayoutWithCenterSliderProps} props - The props for the component.
 * @param {React.Ref} ref - The ref for the component.
 * @returns The rendered component.
 */
export const LayoutWithCenterSlider = forwardRef(
  function LayoutWithCenterSlider(
    { children, aspectRatio, sx, className }: LayoutWithCenterSliderProps,
    ref,
  ): JSX.Element {
    const [slideValue, setSlideValue] = useState(50);

    if (Array.isArray(children) && children.length > 2) {
      throw new Error(
        'LayoutWithCenterSlider only supports two children, but received more than two.',
      );
    }

    const [firstChild, secondChild] = Array.isArray(children)
      ? children.map((child, index) => {
          if (!child) return null;
          if (typeof child === 'string') return child;
          if (typeof child === 'number') return child;
          if (typeof child === 'boolean') return child;
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return cloneElement(child as any, {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ...(child as any).props,
            style: {
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ...(child as any).props?.style,
              flex: index === 0 ? 100 - slideValue : 0 + slideValue,
              height: '100%',
              minWidth: '260px',
              display: 'flex',
              flexDirection: 'column',
            },
          });
        })
      : [children, null];

    return (
      <Box
        className={'layout-with-center-slider ' + className}
        sx={{
          '&:hover .slider': {
            opacity: 1,
          },
          '.component-container': {
            height: '100%',
          },
          '.child-of-layout-with-center-slider > *': {
            height: '100%',
          },
          display: 'flex',
          minWidth: 0,
        }}
      >
        <Box
          ref={ref}
          className={'aspect-ratio-slider'}
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: ['column', 'row'],
            minWidth: 0,
            gap: 1,
            alignItems: 'stretch',
            flex: 1,
            '.layout-container > *': {},
            ...sx,
          }}
        >
          {Array.isArray(children) ? (
            <>
              {firstChild || null}
              {secondChild || null}
            </>
          ) : (
            children
          )}
          {secondChild ? (
            <CenterSlider
              slideValue={slideValue}
              setSlideValue={setSlideValue}
            />
          ) : null}
        </Box>
      </Box>
    );
  },
);

/**
 * An SVG icon representing a layout with the left side smaller than the right side.
 */
export const LeftSmallerThanRightIcon = createSvgIcon(
  <g>
    <rect x="3" y="12" width="5" height="5" rx="1" fill="inherit" />
    <rect x="11" y="7" width="10" height="10" rx="1" fill="inherit" />
  </g>,
  'LeftSmallerThanRight',
);

/**
 * An SVG icon representing a layout with the right side smaller than the left side.
 */
export const RightSmallerThanLeftIcon = createSvgIcon(
  <g>
    <rect
      width="5"
      height="5"
      rx="1"
      transform="matrix(-1 0 0 1 21 12)"
      fill="inherit"
    />
    <rect
      width="10"
      height="10"
      rx="1"
      transform="matrix(-1 0 0 1 13 7)"
      fill="inherit"
    />
  </g>,
  'LeftSmallerThanRight',
);
