import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import { alpha } from '@mui/material/styles';
import {
  LeftSmallerThanRightIcon,
  RightSmallerThanLeftIcon,
} from './LayoutWithCenterSlider';

/**
 * Props for the CenterSlider component.
 */
export interface CenterSliderProps {
  /** The current value of the slider. */
  slideValue: number;
  /** A function to update the value of the slider. */
  setSlideValue: React.Dispatch<React.SetStateAction<number>>;
}

/**
 * A slider component that is centered horizontally and hidden on small screens.
 */
export function CenterSlider({ slideValue, setSlideValue }: CenterSliderProps) {
  return (
    <Stack
      spacing={2}
      direction="row"
      className="slider"
      sx={{
        display: ['none', 'flex'],
        mb: 1,
        width: '100%',
        height: '40px',
        maxWidth: '200px',
        position: 'absolute',
        top: 0,
        opacity: 0,
        transformOrigin: 'center',
        transform: 'translateX(-50%)',
        left: '50%',
        zIndex: 100,
        backgroundColor: (theme) => alpha(theme.palette.background.paper, 0.5),
        borderRadius: '100px',
        p: 1,
      }}
      alignItems="center"
    >
      <RightSmallerThanLeftIcon color="primary" />
      <Slider
        sx={{
          transition: 'opacity 0.3s',
        }}
        track={false}
        aria-labelledby="layout-size-slider"
        value={slideValue}
        onChange={(ev, value) => setSlideValue(value as number)}
      />
      <LeftSmallerThanRightIcon color="primary" />
    </Stack>
  );
}

export default CenterSlider;
