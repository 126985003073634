import {
  AreaConfiguration,
  CollectionConfiguration,
  Components,
  LayoutConfiguration,
  RenderingConfiguration,
  ComponentConfiguration,
} from '@livekatsomo/models';
import Box from '@mui/material/Box';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useMemo } from 'react';

/**
 * Props for the RenderItem component.
 */
export interface RenderItemProps {
  /**
   * The configuration for the item being rendered.
   */
  configuration:
    | CollectionConfiguration
    | ComponentConfiguration
    | AreaConfiguration;
  /**
   * The rendering configuration for the item being rendered.
   */
  renderingConfiguration: RenderingConfiguration;
  /**
   * The layout configuration for the item being rendered.
   */
  layoutConfiguration: LayoutConfiguration;
  /**
   * The ID of the item being rendered.
   */
  id: string;
  /**
   * The components used to render the item.
   */
  components: Components;
  /**
   * An optional component to wrap the rendered component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ComponentWrapper?: OverridableComponent<any>;
  /**
   * An optional component to wrap the rendered collection.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CollectionWrapper?: OverridableComponent<any>;
}

/**
 * Renders a single item in a layout configuration.
 * @param configuration The configuration of the item to render.
 * @param renderingConfiguration The rendering configuration for the layout.
 * @param layoutConfiguration The layout configuration for the layout.
 * @param components The available components to render.
 * @param ComponentWrapper An optional wrapper component for the rendered component.
 * @param CollectionWrapper An optional wrapper component for the rendered collection.
 * @param id The unique identifier for the rendered item.
 * @returns The rendered item.
 */
export function RenderItem({
  configuration,
  renderingConfiguration,
  layoutConfiguration,
  components,
  ComponentWrapper,
  CollectionWrapper,
  id,
  ...htmlProps
}: RenderItemProps) {
  // Check that there is at least one component for the area
  const hasEnabledChildComponents = useMemo(() => {
    return configuration.type === 'area'
      ? configuration.items
          .flatMap((item) =>
            getChildComponents(item, layoutConfiguration, components),
          )
          .filter((item) => item).length > 0
      : true;
  }, [configuration, layoutConfiguration, components]);

  console.log(
    `${
      configuration.type === 'collection' ? configuration.items : ''
    } availableItems`,
    hasEnabledChildComponents,
  );

  if (configuration.type === 'component') {
    const { componentName, componentContainer, ...componentProps } =
      configuration;
    const component =
      componentName in components &&
      components[componentName as keyof Components];
    if (!component) return null;

    const ComponentContainer =
      componentContainer === 'layout'
        ? renderingConfiguration[componentContainer]
        : undefined;

    // Render component

    if (ComponentWrapper)
      return (
        <ComponentWrapper
          id={id}
          className="component-wrapper"
          renderingConfiguration={renderingConfiguration}
          layoutConfiguration={layoutConfiguration}
          ComponentContainer={ComponentContainer}
          configuration={configuration}
          {...htmlProps}
          {...componentProps}
        >
          {component}
        </ComponentWrapper>
      );

    return ComponentContainer ? (
      <ComponentContainer
        className="component-container"
        {...htmlProps}
        {...componentProps}
      >
        {component}
      </ComponentContainer>
    ) : (
      <Box {...htmlProps}>{component}</Box>
    );
  }

  function isValidComponentName(key: string): key is keyof typeof components {
    return key in components;
  }

  // Render collection
  const { items, collectionContainer, ...configProps } = configuration;
  const CollectionContainer = collectionContainer
    ? renderingConfiguration[collectionContainer]
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (renderingConfiguration['layout'] as any);

  const elements = items.filter((item) => {
    const itemConfiguration = layoutConfiguration.collections[item];
    if (!itemConfiguration) {
      console.warn(`Item ${item} not found in layout configuration`);
      return false;
    }
    if (itemConfiguration.type !== 'component') return true;
    if (
      isValidComponentName(itemConfiguration.componentName) &&
      components[itemConfiguration.componentName]
    )
      return true;
    return false;
  });

  const children = elements.map((item) => (
    <RenderItem
      renderingConfiguration={renderingConfiguration}
      layoutConfiguration={layoutConfiguration}
      components={components}
      key={item}
      id={item}
      configuration={layoutConfiguration.collections[item]}
      ComponentWrapper={ComponentWrapper}
      CollectionWrapper={CollectionWrapper}
    />
  ));

  if (CollectionWrapper) {
    return (
      <CollectionWrapper
        id={id}
        className="layout-container"
        items={items || []}
        LayoutContainer={CollectionContainer}
        configuration={configuration}
        {...htmlProps}
        {...configProps}
      >
        {children}
      </CollectionWrapper>
    );
  }

  // When not in edit == no collection wrapper don't render container if
  // child count is 0
  if (children.length === 0 || !hasEnabledChildComponents) return null;

  return (
    <CollectionContainer
      id={id}
      className="layout-container"
      items={items || []}
      {...htmlProps}
      {...configProps}
    >
      {children}
    </CollectionContainer>
  );
}

export default RenderItem;

function getChildComponents(
  item: string,
  layoutConfiguration: LayoutConfiguration,
  components: Components,
): unknown {
  const configuration = layoutConfiguration.collections[item];
  if (configuration.type === 'component') {
    return (
      configuration.componentName in components &&
      components[configuration.componentName as keyof Components]
    );
  } else {
    const items = configuration.items;
    return items.flatMap((item) =>
      getChildComponents(item, layoutConfiguration, components),
    );
  }
}
