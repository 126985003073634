import { DevTool } from '@hookform/devtools';
import {
  AreaConfiguration,
  CollectionConfiguration,
  ComponentConfiguration,
  layoutSchema,
  MaxWidthSchema,
  sxSchema,
} from '@livekatsomo/models';
import {
  RadioField,
  SelectField,
  SwitchField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import ElevationIcon from '@mui/icons-material/Layers';
import PaddingIcon from '@mui/icons-material/Padding';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';
import { Control, UseFormWatch } from 'react-hook-form';
import { BsColumnsGap } from 'react-icons/bs';
import { z } from 'zod';
import { AlignItemsFlexButtonGroupField } from './form-components/AlignItemsFlexButtonGroupField';
import { AlignItemsGridButtonGroupField } from './form-components/AlignItemsGridButtonGroupField';
import GridTemplateField from './form-components/GridTemplateField';
import InputSliderField from './form-components/InputSliderField';
import { JustifyContentFlexButtonGroupField } from './form-components/JustifyContentFlexButtonGroupField';
import { JustifyContentGridButtonGroupField } from './form-components/JustifyContentGridButtonGroupField';
import { ModeField } from './form-components/ModeField';
import { isAreaOrCollection } from './isAreaOrCollection';

const emptyStringSchema = z.literal('');

/**
 * EditFormSchema is a schema object that defines the shape of the data expected by the EditItemForm component.
 * It includes properties for boxed, square, maxWidth, elevation, aspectRatio, sx, maxChildCount, and showPlaceholderInEmptySlots.
 */
export const EditFormSchema = z.object({
  boxed: z.boolean(),
  square: z.boolean(),
  maxWidth: emptyStringSchema.or(MaxWidthSchema),
  elevation: emptyStringSchema.or(layoutSchema.shape.elevation),
  aspectRatio: emptyStringSchema.or(layoutSchema.shape.aspectRatio),
  sx: z.object({
    padding: emptyStringSchema.or(sxSchema.shape.padding),
    gap: emptyStringSchema.or(sxSchema.shape.padding),
    display: z.union([emptyStringSchema, z.literal('flex'), z.literal('grid')]),
    flexDirection: z.union([
      emptyStringSchema,
      z.literal('row'),
      z.literal('column'),
    ]),
    alignItems: emptyStringSchema.or(sxSchema.shape.alignItems),
    justifyContent: emptyStringSchema.or(sxSchema.shape.justifyContent),
    gridTemplateColumns: emptyStringSchema.or(
      sxSchema.shape.gridTemplateColumns,
    ),
  }),
  maxChildCount: emptyStringSchema.or(z.string().transform((value) => +value)),
  showPlaceholderInEmptySlots: z.boolean(),
});

export type LayoutEditFormValues = z.infer<typeof EditFormSchema>;

/**
 * Props for the Edit Layout Item Form component.
 */
export interface EditLayoutItemFormProps {
  /**
   * The item being edited, which can be a CollectionConfiguration, ComponentConfiguration, or AreaConfiguration.
   */
  item: CollectionConfiguration | ComponentConfiguration | AreaConfiguration;
  /**
   * The control object from react-hook-form, which provides methods for registering inputs and validating form data.
   */
  control: Control<LayoutEditFormValues>;
  /**
   * The watch function from react-hook-form, which returns the current values of specified form fields.
   */
  watch: UseFormWatch<LayoutEditFormValues>;
  /**
   * The function to be called when the form is submitted.
   */
  onSubmit: React.FormEventHandler<HTMLFormElement>;
}

/**
 * A form for editing a layout item, which can be a collection, component, or area.
 * @param item The layout item being edited.
 * @param control The `react-hook-form` control object.
 * @param watch The `react-hook-form` watch function.
 * @param onSubmit The form submit event handler.
 */
export function EditLayoutItemForm({
  item,
  control,
  watch,
  onSubmit,
}: EditLayoutItemFormProps) {
  const { t } = useTranslation();
  const [display, direction] = watch(['sx.display', 'sx.flexDirection']);
  return (
    <form>
      {item.type === 'collection' ? (
        <Typography>{t('Layout type: Collection')}</Typography>
      ) : null}
      <Divider />
      <SwitchField label={t('Boxed')} name="boxed" control={control} />
      {watch('boxed') ? (
        <>
          <InputSliderField
            control={control}
            name="elevation"
            label={t('Elevation')}
            min={0}
            max={24}
            icon={<ElevationIcon />}
          />
          <SwitchField
            label={t('Square (no rounded corners)')}
            name="square"
            control={control}
          />
        </>
      ) : null}
      <InputSliderField
        control={control}
        name="sx.padding"
        label={t('Padding')}
        icon={<PaddingIcon />}
      />
      {isAreaOrCollection(item) ? (
        <>
          {item.type === 'collection' &&
          item.collectionContainer === 'layoutWithCenterSlider' ? (
            <InputSliderField
              control={control}
              name="sx.gap"
              label={t('Gap between items')}
              icon={<BsColumnsGap />}
            />
          ) : (
            <>
              <SelectField
                control={control}
                name="maxWidth"
                fullWidth
                label={t('Max Width')}
                options={MaxWidthSchema.options}
                optionSelector={(option) => option.value || ''}
                optionViewer={(option) => option.value || 'none'}
              />
              <ModeField control={control} />
            </>
          )}

          {display === 'flex' ? (
            <>
              <RadioField
                name="sx.flexDirection"
                control={control}
                label={t('Direction')}
                defaultValue="column"
                options={['row', 'column']}
              />
              <InputSliderField
                control={control}
                name="sx.gap"
                label={t('Gap between items')}
                icon={<BsColumnsGap />}
              />
              <AlignItemsFlexButtonGroupField
                control={control}
                direction={direction ? direction : 'column'}
              />
              <JustifyContentFlexButtonGroupField
                control={control}
                direction={direction ? direction : 'column'}
              />
            </>
          ) : display === 'grid' ? (
            <>
              <InputSliderField
                control={control}
                name="sx.gap"
                label={t('Gap between items')}
                icon={<BsColumnsGap />}
              />
              <AlignItemsGridButtonGroupField control={control} />
              <JustifyContentGridButtonGroupField control={control} />
              <GridTemplateField control={control} />
            </>
          ) : null}
        </>
      ) : null}
      <DevTool control={control} placement="top-left" />
    </form>
  );
}
