/**
 * This file contains a mock object of components for an event layout.
 * Each component is a Box with specific styling and text.
 * The components are used to create a visual representation of an event layout.
 */
import { Components } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import invert from 'invert-color';
import stc from 'string-to-color';

export const componentMocksForEventLayout: Components = {
  countdown: (
    <Box
      sx={{
        backgroundColor: stc('Countdown'),
        color: invert(stc('Countdown'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Countdown
    </Box>
  ),
  agenda: (
    <Box
      sx={{
        aspectRatio: '1/1',
        backgroundColor: stc('Agenda'),
        color: invert(stc('Agenda'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Agenda
    </Box>
  ),
  chat: (
    <Box
      sx={{
        backgroundColor: stc('Chat'),
        color: invert(stc('Chat'), true),
        p: 4,
        aspectRatio: '1/2',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Chat
    </Box>
  ),
  details: (
    <Box
      sx={{
        backgroundColor: stc('Details'),
        color: invert(stc('Details'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Details
    </Box>
  ),
  player: (
    <Box
      sx={{
        aspectRatio: '16/9',
        maxHeight: '100%',
        position: 'relative',
        width: '100%',
        backgroundColor: stc('Left'),
        color: invert(stc('Left'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Player
    </Box>
  ),
  slides: (
    <Box
      sx={{
        aspectRatio: '16/9',
        maxHeight: '100%',
        position: 'relative',
        width: '100%',
        backgroundColor: stc('Left'),
        color: invert(stc('Left'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Slides
    </Box>
  ),
  slideThumbs: (
    <Box
      sx={{
        aspectRatio: '16 / 3',
        backgroundColor: stc('SlideThumbs'),
        color: invert(stc('SlideThumbs'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Slide Thumbs
    </Box>
  ),
  speakers: (
    <Box
      sx={{
        backgroundColor: stc('Speakers'),
        color: invert(stc('Speakers'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Speakers
    </Box>
  ),
  sponsors: (
    <Box
      sx={{
        backgroundColor: stc('Sponsors'),
        color: invert(stc('Sponsors'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Sponsors
    </Box>
  ),
  slido: (
    <Box
      sx={{
        backgroundColor: stc('Slido'),
        color: invert(stc('Slido'), true),
        p: 4,
        aspectRatio: '1/2',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Slido
    </Box>
  ),
  summary: (
    <Box
      sx={{
        backgroundColor: stc('Event Summary'),
        color: invert(stc('Event Summary'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Event Summary
    </Box>
  ),
  trackSummary: (
    <Box
      sx={{
        backgroundColor: stc('Track Summary'),
        color: invert(stc('Track Summary'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Track Summary
    </Box>
  ),
  wallsio: (
    <Box
      sx={{
        backgroundColor: stc('Wallsio'),
        color: invert(stc('Wallsio'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        aspectRatio: '1/1',
      }}
    >
      Wallsio
    </Box>
  ),
  speedTest: (
    <Box
      sx={{
        backgroundColor: stc('SpeedTest'),
        color: invert(stc('SpeedTest'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Speed Test
    </Box>
  ),
  browserTest: (
    <Box
      sx={{
        backgroundColor: stc('BrowserTest'),
        color: invert(stc('BrowserTest'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Browser Test
    </Box>
  ),
  surveyForm: (
    <Box
      sx={{
        backgroundColor: stc('SurveyForm'),
        color: invert(stc('SurveyForm'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Survey Form
    </Box>
  ),
  downloadFiles: (
    <Box
      sx={{
        backgroundColor: stc('DownloadFiles'),
        color: invert(stc('DownloadFiles'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Download Files
    </Box>
  ),
};
