import { ClientRect } from '@dnd-kit/core';

/**
 * Calculates the gap between the current item and the previous or next item in a vertical list.
 * @param clientRects An array of ClientRect objects representing the bounding rectangles for each item in the list.
 * @param index The index of the current item.
 * @param activeIndex The index of the currently active item.
 * @returns The gap between the current item and the previous or next item, or 0 if the current item is undefined.
 */
export function getItemGap(
  clientRects: ClientRect[],
  index: number,
  activeIndex: number,
) {
  // If the current rect is undefined, return 0.
  const currentRect: ClientRect | undefined = clientRects[index];
  const previousRect: ClientRect | undefined = clientRects[index - 1];
  const nextRect: ClientRect | undefined = clientRects[index + 1];

  if (!currentRect) {
    return 0;
  }

  // If the active index is less than the index, return the difference between the top of the current rect and the bottom of the previous rect.
  if (activeIndex < index) {
    return previousRect
      ? currentRect.top - (previousRect.top + previousRect.height)
      : nextRect
      ? nextRect.top - (currentRect.top + currentRect.height)
      : 0;
  }

  // Otherwise, if the active index is greater than the index, return the difference between the top of the next rect and the bottom of the current rect.
  return nextRect
    ? nextRect.top - (currentRect.top + currentRect.height)
    : previousRect
    ? currentRect.top - (previousRect.top + previousRect.height)
    : 0;
}
