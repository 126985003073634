import { CustomerComponentName } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import invert from 'invert-color';
import { ReactNode } from 'react';
import stc from 'string-to-color';

/**
 * A record of React nodes representing mock components for each customer layout component.
 * The keys of the record correspond to the names of the customer layout components.
 */
export const componentMocksForCustomerLayout: Record<
  CustomerComponentName,
  ReactNode
> = {
  events: (
    <Box
      sx={{
        backgroundColor: stc('Events'),
        color: invert(stc('Events'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Events
    </Box>
  ),
  banner: (
    <Box
      sx={{
        backgroundColor: stc('Banner'),
        color: invert(stc('Banner'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      Banner
    </Box>
  ),
  featuredEvents: (
    <Box
      sx={{
        backgroundColor: stc('FeaturedEvents'),
        color: invert(stc('FeaturedEvents'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      FeaturedEvents
    </Box>
  ),
  vodEvents: (
    <Box
      sx={{
        backgroundColor: stc('VodEvents'),
        color: invert(stc('VodEvents'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      VodEvents
    </Box>
  ),
  categoryEvents: (
    <Box
      sx={{
        backgroundColor: stc('CategoryEvents'),
        color: invert(stc('CategoryEvents'), true),
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      CategoryEvents
    </Box>
  ),
};
