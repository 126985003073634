import { LayoutProps } from '@livekatsomo/models';
import Box, { BoxProps } from '@mui/material/Box';
import Paper, { PaperProps } from '@mui/material/Paper';

export type FullWidthLayoutContainerProps = (BoxProps | PaperProps) &
  LayoutProps;

/**
 * A layout container that spans the full width of its parent container.
 * @param boxed - If true, the container will be rendered with a Paper component. Otherwise, it will be rendered with a Box component.
 * @param sx - Additional CSS styles to apply to the container.
 * @param maxWidth - The maximum width of the container.
 * @param props - Additional props to pass to the container component.
 * @returns The FullWidthLayoutContainer component.
 */
export function FullWidthLayoutContainer({
  boxed,
  sx,
  maxWidth,
  ...props
}: FullWidthLayoutContainerProps) {
  const sxProps = {
    ...sx,
  };
  return boxed ? (
    <Paper component="div" sx={sxProps} {...props} />
  ) : (
    <Box component="div" sx={sxProps} {...props} />
  );
}
