import {
  AreaConfiguration,
  CollectionConfiguration,
  ComponentConfiguration,
} from '@livekatsomo/models';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'next-i18next';
import { LayoutDrawerMenuItems } from './LayoutDrawerMenuItems';
import { DrawerHeader, drawerWidth } from './LayoutEditor';
import {
  LayoutEditorEditItemDrawerProps,
  LayoutItemEditor,
} from './edit-dialog/LayoutEditorEditItemDrawer';

/**
 * Props for the NewLayoutEditorDrawer component.
 */
export interface NewLayoutEditorDrawerPropsype {
  open: boolean;
  /**
   * Whether the layout is dirty or not.
   */
  isDirty: boolean;
  /**
   * Whether the layout is submitting or not.
   */
  isSubmitting: boolean;
  /**
   * The item to edit in the layout.
   */
  editItem: {
    id: string;
    configuration:
      | CollectionConfiguration
      | AreaConfiguration
      | ComponentConfiguration;
  } | null;
  /**
   * Callback function to close the dialog.
   */
  onClose: () => void;
  /**
   * Callback function to close the drawer.
   */
  onDrawerClose: () => void;
  /**
   * Whether the drawer is open or not.
   */
  /**
   * Callback function to edit an item in the layout.
   */
  onEditItem: LayoutEditorEditItemDrawerProps['onSubmit'];
  /**
   * Callback function to set the item to edit in the layout.
   */
  onSetEditItem: React.Dispatch<
    React.SetStateAction<{
      id: string;
      configuration:
        | CollectionConfiguration
        | AreaConfiguration
        | ComponentConfiguration;
    } | null>
  >;
  /**
   * Callback function to reset the layout.
   */
  onReset: () => void;
  /**
   * Callback function to cancel editing an item in the layout.
   */
  onCancel: () => void;
  /**
   * Callback function to edit the base layout.
   */
  onEditBaseLayout: () => void;
  /**
   * Callback function to submit the layout.
   */
  onSubmit: () => void;
}

/**
 * A drawer component for the layout editor.
 * @returns  The JSX element for the component.
 */
export function LayoutEditorDrawer({
  editItem,
  isDirty,
  isSubmitting,
  open,
  onClose,
  onDrawerClose,
  onEditItem,
  onSetEditItem,
  onReset,
  onCancel,
  onEditBaseLayout,
  onSubmit,
}: NewLayoutEditorDrawerPropsype) {
  const { t } = useTranslation();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
      aria-label={t('Layout editor drawer') || 'Layout editor drawer'}
    >
      <DrawerHeader>
        <IconButton onClick={onDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </DrawerHeader>
      {editItem ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            padding: 1,
            gap: 1,
          }}
        >
          <LayoutItemEditor
            key={editItem.id}
            onSubmit={onEditItem}
            onClose={() => onSetEditItem(null)}
            item={editItem}
            onCancel={() => onCancel()}
          />
        </Box>
      ) : (
        <LayoutDrawerMenuItems
          onEditBaseLayout={onEditBaseLayout}
          isDirty={isDirty}
          isSubmitting={isSubmitting}
          onClose={onClose}
          onReset={onReset}
          onSubmit={onSubmit}
        />
      )}
    </Drawer>
  );
}

export default LayoutEditorDrawer;
