import {
  closestCorners,
  CollisionDetection,
  pointerWithin,
} from '@dnd-kit/core';

/**
 * Returns an array of droppable elements that intersect with the provided draggable element.
 * If there are droppables intersecting with the pointer, it returns those.
 * If there are no droppables intersecting with the pointer, it finds droppables intersecting with the active draggable.
 *
 * @param args - The arguments for collision detection.
 * @returns An array of droppable elements that intersect with the provided draggable element.
 */
export function getIntersection(args: Parameters<CollisionDetection>[0]) {
  const pointerIntersections = pointerWithin(args);
  const intersections =
    pointerIntersections.length > 0
      ? // If there are droppables intersecting with the pointer, return those
        pointerIntersections
      : // If there are no droppables intersecting with the pointer, find droppables intersecting with the active draggable
        closestCorners(args);
  return intersections;
}
