import { SortingStrategy } from '@dnd-kit/sortable';
import { getItemGap } from './getItemGap';

/**
 * Default scale object for the vertical list sorting strategy.
 */
const defaultScale = {
  scaleX: 1,
  scaleY: 1,
};

// Sort a vertical list of items
export const verticalListSortingStrategy: SortingStrategy = ({
  // The index of the currently active item
  activeIndex,
  // The DOMRect of the currently active item
  activeNodeRect: fallbackActiveRect,
  // The index of the item we're sorting
  index,
  // A list of all DOMRects in the list
  rects,
  // The index of the item we're moving the currently active item over
  overIndex,
}) => {
  // Get the DOMRect of the currently active item
  const activeNodeRect = rects[activeIndex] ?? fallbackActiveRect;

  // If the active node rect doesn't exist, return null
  if (!activeNodeRect) {
    return null;
  }

  // If the index of the item we're sorting is the active item, return the
  // transform that will position the active item over the item we're moving
  // over
  if (index === activeIndex) {
    const overIndexRect = rects[overIndex];

    // If the rect of the item we're moving over doesn't exist, return null
    if (!overIndexRect) {
      return null;
    }

    // Return the transform to position the active item over the item we're
    // moving over
    return {
      // The X position of the active item will always be 0
      x: 0,
      // The Y position of the active item will be the difference between the
      // top of the item we're moving over and the bottom of the active item
      y:
        activeIndex < overIndex
          ? overIndexRect.top +
            overIndexRect.height -
            (activeNodeRect.top + activeNodeRect.height)
          : overIndexRect.top - activeNodeRect.top,
      // The scale will always be 1
      ...defaultScale,
    };
  }

  const itemGap = getItemGap(rects, index, activeIndex);

  // If the index is greater than the active index, and less than the over index, move the item upwards.
  if (index > activeIndex && index <= overIndex) {
    //move upwards

    return {
      x: 0,
      y: -activeNodeRect.height - itemGap,
      ...defaultScale,
    };
  }

  // If the index is less than the active index, and greater than the over index, move the item downwards.
  if (index < activeIndex && index >= overIndex) {
    // move downwards

    return {
      x: 0,
      y: activeNodeRect.height + itemGap,
      ...defaultScale,
    };
  }

  // Otherwise, don't move the item.
  return {
    x: 0,
    y: 0,
    ...defaultScale,
  };
};
