import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import Tooltip from '@mui/material/Tooltip';

/**
 * A custom toggle button component that wraps the Material-UI ToggleButton component and adds a tooltip.
 * @param {ToggleButtonProps} props - The props for the ToggleButton component.
 * @returns A ToggleButton component wrapped in a Tooltip component.
 */
export function CustomToggleButton({ ...props }: ToggleButtonProps) {
  return (
    <Tooltip title={props['aria-label']}>
      <ToggleButton {...props} />
    </Tooltip>
  );
}
