import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { ConfigurationType } from '@livekatsomo/models';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useEditLayout } from '../layout-rendering/useEditLayout';

/**
 * Props for the LayoutMenuButton component.
 */
export interface LayoutMenuButtonProps {
  /**
   * The type of configuration.
   */
  type: ConfigurationType;
  /**
   * The ID of the configuration.
   */
  id: string;
  /**
   * The listeners for the synthetic events.
   */
  listeners: SyntheticListenerMap | undefined;
  /**
   * Whether to disable the add button.
   */
  disableAdd?: boolean;
  /**
   * A function to set the activator node reference.
   * @param node The activator node.
   */
  setActivatorNodeRef: (node: HTMLElement | null) => void;
}

/**
 * Renders a button for the layout menu.
 * @param {LayoutMenuButtonProps} props - The props for the LayoutMenuButton component.
 * @param props.type - The type of the button.
 * @param props.id - The id of the button.
 * @param props.listeners - The listeners for the button.
 * @param {boolean} props.disableAdd - Whether to disable the add button.
 * @param props.setActivatorNodeRef - The function to set the activator node reference.
 * @returns The LayoutMenuButton component.
 */
export function LayoutMenuButton({
  type,
  id,
  listeners,
  disableAdd,
  setActivatorNodeRef,
}: LayoutMenuButtonProps) {
  const {
    deleteConfiguration,
    showEdit: showEditDialog,
    showAddDialog,
  } = useEditLayout();
  let color:
    | 'inherit'
    | 'secondary'
    | 'primary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning'
    | undefined;

  switch (type) {
    case 'component':
      color = 'secondary';
      break;
    case 'collection':
      color = 'primary';
      break;
    case 'area':
      color = 'success';
      break;
    default:
      color = 'error';
  }
  return (
    <Box
      className="layout-menu-button"
      sx={{
        position: 'absolute',
        transition: 'visibility 0.5s, opacity 0.5s',
        top: 0,
        right: type === 'component' ? 0 : undefined,
        left: type !== 'component' ? 0 : undefined,
        zIndex: 2,
        pointerEvents: 'all',
        opacity: 0,
        visibility: 'hidden',
        '&:hover': {
          zIndex: 3,
        },
      }}
    >
      <ButtonGroup variant="contained" orientation="vertical" color={color}>
        <Button ref={setActivatorNodeRef} {...listeners}>
          <DragIcon />
        </Button>

        <Button onClick={() => showEditDialog(id)}>
          <EditIcon />
        </Button>

        <Button onClick={() => deleteConfiguration(id)}>
          <DeleteIcon />
        </Button>

        {type !== 'component' && !disableAdd ? (
          <Button onClick={() => showAddDialog(id)}>
            <AddIcon />
          </Button>
        ) : null}
      </ButtonGroup>
    </Box>
  );
}

export default LayoutMenuButton;
