import {
  closestCenter,
  CollisionDetection,
  DndContext,
  DragEndEvent,
  DragOverlay,
  getFirstCollision,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { LayoutConfiguration } from '@livekatsomo/models';
import { debounce } from '@mui/material/utils';
import {
  ComponentProps,
  Dispatch,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { SortableCollectionWrapper } from '../layout-containers/SortableCollectionWrapper';
import { SortableComponentWrapper } from '../layout-containers/SortableComponentWrapper';
import { BaseContainer } from './BaseContainer';
import { dragOver } from './dragOver';
import { getClosestContainerFromParent } from './getClosestContainerFromParent';
import { getIntersection } from './getIntersection';
import { isContainer } from './isContainer';
import { RenderItem } from './RenderArea';
import { RenderLayoutProps } from './RenderLayout';
import { verticalListSortingStrategy } from './sortable/verticalListSortingStrategy';

export const TRASH_ID = 'void';
export const PLACEHOLDER_ID = 'PLACEHOLDER';

/**
 * Props for the `RenderEditLayout` component.
 */
export type RenderEditLayoutProps = {
  /** The ID of the currently active item. */
  activeId: UniqueIdentifier | null;
  /** The cloned configuration of the layout. */
  clonedConfig: LayoutConfiguration | null;
  /** The function to set the configuration of the layout. */
  setConfig: Dispatch<React.SetStateAction<LayoutConfiguration>>;
  /** The function to set the cloned configuration of the layout. */
  setClonedConfig: Dispatch<React.SetStateAction<LayoutConfiguration | null>>;
  /** The function to set the ID of the currently active item. */
  setActiveId: Dispatch<React.SetStateAction<UniqueIdentifier | null>>;
} & RenderLayoutProps &
  Omit<ComponentProps<typeof RenderItem>, 'configuration'>;

/**
 * Renders the edit layout component with drag and drop functionality.
 * @returns The rendered edit layout component.
 */
export function RenderEditLayout({
  layoutConfiguration: config,
  renderingConfiguration,
  components,
  activeId,
  clonedConfig,
  setConfig,
  setClonedConfig,
  setActiveId,
}: RenderEditLayoutProps) {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        tolerance: 5,
        delay: 0,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const recentlyMovedToNewContainer = useRef(false);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const currentOverId = useRef<UniqueIdentifier | null>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      // reset recentlyMovedToNewContainer after a frame
      recentlyMovedToNewContainer.current = false;
    });
  }, [config]);

  const { collectionContainer, items, ...props } =
    config.collections.baseLayout;

  const itemsWithName = items?.map((item) => ({
    id: item,
    configuration: config.collections[item],
  }));

  const LayoutContainer = collectionContainer
    ? renderingConfiguration[collectionContainer]
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (renderingConfiguration['layout'] as any);

  // Hande drop after drag
  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      console.log('drag end', event);

      const sourceContainer = over?.data.current?.sortable
        .containerId as string;
      const destinationContainer = active.data.current?.sortable
        .containerId as string;

      if (
        over &&
        sourceContainer &&
        destinationContainer &&
        sourceContainer === destinationContainer
      ) {
        setConfig((prevConfig) => {
          const configuration = prevConfig.collections[sourceContainer];
          if (!configuration || configuration.type === 'component') {
            return prevConfig;
          }
          const items = configuration.items;
          const oldIndex = items.findIndex((item) => item === active.id);
          const newIndex = items.findIndex((item) => item === over.id);
          const newItems = arrayMove(items, oldIndex, newIndex);
          return {
            ...prevConfig,
            collections: {
              ...prevConfig.collections,
              [sourceContainer]: {
                ...configuration,
                items: newItems,
              },
            },
          };
        });
      }
      currentOverId.current = null;
    },
    [setConfig],
  );

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    (args) => {
      // Start by finding any droppable intersecting with the pointer
      const intersections = getIntersection(args);

      // Check first if there are intersections with placeholder or trash
      const placeholderIntersection = intersections.find(
        (intersection) => intersection.id === PLACEHOLDER_ID,
      );

      // If there are intersections with placeholder, return those
      if (placeholderIntersection) {
        return [{ id: PLACEHOLDER_ID }];
      }

      // If there are no intersections with placeholder, check if there are intersections with trash
      const trashIntersection = intersections.find(
        (intersection) => intersection.id === TRASH_ID,
      );

      // If there are intersections with trash, return those
      if (trashIntersection) {
        return [trashIntersection];
      }

      // If active item is a container. Find the closest droppable container with same parent container id
      if (activeId && isContainer(config, activeId)) {
        return getClosestContainerFromParent(config, args);
      }

      // rectIntersection(args);
      // closestCenter(args);
      // If there are no droppables intersecting with the pointer, find droppables with closest center with the active draggable
      let overId = getFirstCollision(intersections, 'id');

      currentOverId.current = overId;

      if (overId != null) {
        // If the intersecting droppable is the active item, return early
        if (overId === activeId) {
          // Collision with self
          return intersections;
        }

        // If the intersecting droppable is the placeholder, return early
        if (overId === PLACEHOLDER_ID) {
          // Collision with placeholder
          return intersections;
        }

        // If the intersecting droppable is the trash, return early
        if (overId === TRASH_ID) {
          // Remove this if you're not using trashable functionality in your app
          // Collision with trash
          return intersections;
        }

        console.log('collisionDetectionStrategy', { activeId, overId, args });

        const configuration = config.collections[overId];
        if (configuration.type === 'collection') {
          console.log('colliding with container', { activeId, overId, args });
          // If the intersecting droppable is a container
          // First time intersecting with a container the pointer is over the container but not yet
          // over any of the items in the container
          // We should return closest center of the items in the container
          const containerItems = configuration.items;

          if (containerItems.length === 0) {
            // If the container is empty
            // check if we are intecting with the placeholder

            if (intersections.findIndex((i) => i.id === PLACEHOLDER_ID) > -1) {
              // If we are intersecting with the placeholder, return early
              return [{ id: PLACEHOLDER_ID }];
            }
          }

          // Get the closest droppable within that container
          overId = closestCenter({
            ...args,
            droppableContainers: args.droppableContainers.filter(
              (container) =>
                container.id !== overId &&
                containerItems.includes(container.id as string),
            ),
          })[0]?.id;
          currentOverId.current = overId;
        }

        lastOverId.current = overId;

        return [{ id: overId }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, config],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDragOver = useCallback(
    debounce(
      dragOver(config.collections, setConfig, recentlyMovedToNewContainer),
      500,
    ),
    [config.collections, recentlyMovedToNewContainer],
  );

  const onDragCancel = useCallback(
    function () {
      if (clonedConfig) {
        // Reset items to their original state in case items have been
        // Dragged across containers
        setConfig(clonedConfig);
      }

      setActiveId(null);
      setClonedConfig(null);
      currentOverId.current = null;
    },
    [clonedConfig, setActiveId, setClonedConfig, setConfig],
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      onDragEnd={handleDragEnd}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedConfig(config);
      }}
      onDragCancel={onDragCancel}
      onDragOver={handleDragOver}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
    >
      <BaseContainer
        id="baseLayout"
        className="layout-container"
        LayoutContainer={LayoutContainer}
        {...props}
      >
        <SortableContext
          id="baseLayout"
          items={config.collections.baseLayout.items}
          strategy={verticalListSortingStrategy}
        >
          {itemsWithName.map(({ id, configuration }) => (
            <RenderItem
              key={id}
              configuration={configuration}
              renderingConfiguration={renderingConfiguration}
              layoutConfiguration={config}
              id={id}
              components={components}
              ComponentWrapper={SortableComponentWrapper}
              CollectionWrapper={SortableCollectionWrapper}
            />
          ))}
        </SortableContext>
      </BaseContainer>
      <DragOverlay>
        {activeId ? (
          <RenderItem
            id={activeId as string}
            renderingConfiguration={renderingConfiguration}
            layoutConfiguration={config}
            components={components}
            configuration={config.collections[activeId]}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
}

export default RenderEditLayout;
