import { useSortable } from '@dnd-kit/sortable';
import Button from '@mui/material/Button';
import { useEditLayout } from '../useEditLayout';
import { useTranslation } from 'next-i18next';

/**
 * Props for the Placeholder component.
 */
export interface PlaceholderProps {
  collectionId: string;
}

/**
 * A component that renders a placeholder button for adding new items to a sortable collection.
 * @param {PlaceholderProps} props - The props for the component.
 * @param props.collectionId - The ID of the collection to which the placeholder belongs.
 * @returns  - The JSX element for the component.
 */
export function Placeholder({ collectionId }: PlaceholderProps) {
  const { t } = useTranslation();
  const { showAddDialog } = useEditLayout();
  const { setNodeRef, isOver } = useSortable({
    id: 'PLACEHOLDER',
  });

  return (
    <Button
      variant="contained"
      ref={setNodeRef}
      onClick={() => showAddDialog(collectionId)}
      color={isOver ? 'error' : 'primary'}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        m: 2,
        borderRadius: 5,
      }}
    >
      + {t('Add new')}
    </Button>
  );
}

export default Placeholder;
