import { Control, FieldValues, Path, useController } from 'react-hook-form';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { CustomToggleButton } from './CustomToggleButton';
import { useTranslation } from 'next-i18next';

/**
 * Props for the ModeField component.
 *
 * @typeParam FormValues The type of the form values.
 */
export interface ModeFieldProps<FormValues extends FieldValues> {
  control: Control<FormValues>;
}

/**
 * Renders a mode field component that allows the user to switch between "flex" and "grid" display modes.
 * @typeParam FormValues - The type of the form values object.
 * @param props - The component props.
 * @param {import('react-hook-form').Control<FormValues>} props.control - The control object from react-hook-form.
 * @returns  - The JSX element for the mode field component.
 */
export function ModeField<FormValues extends FieldValues>({
  control,
}: ModeFieldProps<FormValues>) {
  const { t } = useTranslation();
  const { field: modeField } = useController({
    name: 'sx.display' as Path<FormValues>,
    control,
  });
  const { field: directionField } = useController({
    name: 'direction' as Path<FormValues>,
    control,
  });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newMode: string,
  ) => {
    modeField.onChange(newMode);
    if (newMode === 'flex') {
      directionField.onChange('row');
    } else {
      directionField.onChange('column');
    }
  };

  return (
    <Box>
      <Typography gutterBottom>
        {t('Display mode')}: <em>{modeField.value}</em>
      </Typography>
      <ToggleButtonGroup
        value={modeField.value}
        onChange={handleChange}
        exclusive
        aria-label={t('Display mode') || 'Display mode'}
      >
        <CustomToggleButton value="flex" aria-label={'flex'}>
          flex
        </CustomToggleButton>
        <CustomToggleButton value="grid" aria-label={'grid'}>
          grid
        </CustomToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
