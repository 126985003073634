import { UniqueIdentifier } from '@dnd-kit/core';
import { CollectionsConfiguration } from '@livekatsomo/models';
import { createContext } from 'react';

/**
 * Context object for managing the state of editing collections configurations.
 * Provides methods for deleting, editing, and adding configurations, as well as
 * showing the edit and add dialogs.
 */
export const EditContext = createContext<{
  activeId: UniqueIdentifier | null;
  deleteConfiguration: (id: string) => void;
  editConfiguration: (
    id: string,
    configuration: CollectionsConfiguration,
  ) => void;
  showEdit: (id: string) => void;
  showAddDialog: (collectionId: string) => void;
} | null>(null);
