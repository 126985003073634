import { LayoutProps } from '@livekatsomo/models';
import MoveIcon from '@mui/icons-material/DragIndicator';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import { animated, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { forwardRef, useRef, useState } from 'react';

/**
 * An animated version of the Box component.
 */
const AnimatedBox = animated(Box);

/**
 * A container component that maintains a fixed aspect ratio and allows the user to adjust it using a slider.
 *
 * @param children The child elements to be rendered inside the container.
 * @param sx The inline style object to apply to the container.
 * @param props The additional props to apply to the container.
 * @param ref A ref object to be attached to the container.
 * @returns A React component that renders the aspect ratio container.
 */
export const AspectRatioContainer = forwardRef(function AspectRatioContainer(
  { children, sx, ...props }: Omit<LayoutProps, 'maxWidth'>,
  ref,
) {
  const boundRef = useRef(null);
  const [storedAspectRatio, setStoredAspectRatio] = useState(16);
  const [{ aspectRatio }, api] = useSpring(() => ({
    aspectRatio: storedAspectRatio,
  }));
  const bind = useDrag(
    ({ down, offset: [mx, my], last }) => {
      const newAspectRatio = 16 + mx / 50;
      console.log({ down, aspectRatio: newAspectRatio });
      api.start({
        aspectRatio: newAspectRatio,
        immediate: down,
      });
      setStoredAspectRatio(newAspectRatio);
    },
    {
      rubberband: true,
      pointer: {
        lock: false,
      },
      bounds: boundRef,
    },
  );

  return (
    <>
      <Box
        ref={boundRef}
        sx={{
          maxHeight: '100%',
          width: '50%',
          aspectRatio: '1/1',
          position: 'absolute',
          bgcolor: 'transparent',
        }}
      />
      <AnimatedBox
        ref={ref}
        className={'aspect-ratio-slider'}
        sx={{
          transition: 'padding 0.5s',
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
          '&:hover .handle': {
            opacity: 1,
          },
          ...sx,
        }}
        style={{ aspectRatio: aspectRatio.to((value) => `${value}/9`) }}
        {...props}
      >
        <Box
          sx={{
            width: '100%',
          }}
        >
          {children}
        </Box>
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            width: '10px',
          }}
        >
          <Fab
            {...bind()}
            className="handle"
            disableRipple={true}
            color="primary"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transformOrigin: 'center',
              transform: 'translateX(-50%) translateY(-50%)',
              opacity: 0,
              zIndex: 1,
              cursor: 'ew-resize',
              display: ['none', 'flex'],
            }}
          >
            <MoveIcon />
          </Fab>
        </Box>
      </AnimatedBox>
    </>
  );
});

export default AspectRatioContainer;
