import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import {
  RxAlignBottom,
  RxAlignCenterVertically,
  RxAlignTop,
  RxStretchVertically,
} from 'react-icons/rx';
import { CustomToggleButton } from './CustomToggleButton';
import { useTranslation } from 'next-i18next';

/**
 * Props for the AlignItemsGridButtonGroupField component.
 * @typeParam FormValues The type of the form values.
 */
export interface AlignItemsGridButtonGroupFieldProps<
  FormValues extends FieldValues,
> {
  control: Control<FormValues>;
}

/**
 * A form field component that renders a toggle button group for aligning items vertically in a grid layout.
 *
 * @typeParam FormValues The type of form values object that this field is a part of.
 *
 * @param props The component props.
 * @param {import('react-hook-form').Control<FormValues>} props.control The `react-hook-form` control object.
 *
 * @returns The JSX element that represents the component.
 */
export function AlignItemsGridButtonGroupField<FormValues extends FieldValues>({
  control,
}: AlignItemsGridButtonGroupFieldProps<FormValues>) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'sx.alignItems' as Path<FormValues>,
    control,
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography gutterBottom>
        {t('Align Items')}: <em>{field.value}</em>
      </Typography>
      <ToggleButtonGroup
        value={field.value}
        onChange={(event, alignment) => {
          field.onChange(alignment || '');
        }}
        exclusive
        aria-label={t('alignment') || 'alignment'}
      >
        <CustomToggleButton value={'start'} aria-label={'start'}>
          <RxAlignTop />
        </CustomToggleButton>
        <CustomToggleButton value="center" aria-label={'center'}>
          <RxAlignCenterVertically />
        </CustomToggleButton>
        <CustomToggleButton value="end" aria-label={'end'}>
          <RxAlignBottom />
        </CustomToggleButton>
        <CustomToggleButton value="stretch" aria-label={'stretch'}>
          <RxStretchVertically />
        </CustomToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="error">
        {error?.message}
      </Typography>
    </Box>
  );
}
