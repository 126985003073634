import Edit from '@mui/icons-material/Edit';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'next-i18next';

/**
 * Props for the LayoutDrawerMenuItems component.
 */
export interface LayoutDrawerMenuItemsProps {
  /**
   * Boolean indicating whether the layout is dirty.
   */
  isDirty: boolean;
  /**
   * Boolean indicating whether the form is submitting.
   */
  isSubmitting: boolean;
  /**
   * Function to close dialog.
   */
  onClose: () => void;
  /**
   * Function to edit the base layout.
   */
  onEditBaseLayout: () => void;
  /**
   * Function to reset the form.
   */
  onReset: () => void;
  /**
   * Function to submit the form.
   */
  onSubmit: () => void;
}

/**
 * Renders the menu items for the layout drawer.
 * @returns  - The JSX element representing the layout drawer menu items.
 */
export function LayoutDrawerMenuItems({
  isDirty,
  isSubmitting,
  onClose,
  onEditBaseLayout,
  onReset,
  onSubmit,
}: LayoutDrawerMenuItemsProps) {
  const { t } = useTranslation();
  return (
    <>
      <Divider />
      <List>
        <ListItemButton onClick={onEditBaseLayout}>
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText primary={t('Edit baselayout')} />
        </ListItemButton>
      </List>
      <Divider />
      <LoadingButton
        onClick={onSubmit}
        disabled={!isDirty}
        loading={isSubmitting}
      >
        {t('Update')}
      </LoadingButton>
      <Button onClick={onReset} disabled={!isDirty}>
        {t('Reset')}
      </Button>
      <Button onClick={onClose}>{t('Close')}</Button>
    </>
  );
}

export default LayoutDrawerMenuItems;
