import {
  AreaConfiguration,
  CollectionConfiguration,
  ComponentConfiguration,
} from '@livekatsomo/models';

/**
 * Determines whether the given item is an area or a collection.
 * @param item The item to check.
 * @returns True if the item is an area or a collection, false otherwise.
 */
export function isAreaOrCollection(
  item: CollectionConfiguration | ComponentConfiguration | AreaConfiguration,
) {
  return item.type !== 'component';
}
