import { ConfigurationType } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import { forwardRef } from 'react';

/**
 * A component that renders an overlay with a colored border based on the provided `type` prop.
 * @param type - The type of configuration for the overlay. Can be one of: 'component', 'collection', or 'area'.
 * @param debug - Whether to enable debug mode. Defaults to `true`.
 * @param ref - A ref object that can be used to access the underlying DOM element.
 * @returns A React component.
 */
export const LayoutOverlay = forwardRef(function LayoutOverlay(
  { type, debug = true }: { type: ConfigurationType; debug?: boolean },
  ref,
) {
  let color: string;

  switch (type) {
    case 'component':
      color = 'secondary.main';
      break;
    case 'collection':
      color = 'primary.main';
      break;
    case 'area':
      color = 'success.main';
      break;
    default:
      color = 'error.main';
  }
  return (
    <Box
      className="layout-overlay"
      ref={ref}
      style={{
        position: 'absolute',
      }}
      sx={{
        transition: 'opacity 1s',
        opacity: 0,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        border: `12px solid`,
        borderColor: color,
        zIndex: 1,
        pointerEvents: 'none',
        '&:hover': {
          opacity: 1,
        },
      }}
    ></Box>
  );
});

export default LayoutOverlay;
