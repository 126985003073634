import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { BsDistributeHorizontal, BsDistributeVertical } from 'react-icons/bs';
import {
  RxAlignBottom,
  RxAlignCenterHorizontally,
  RxAlignCenterVertically,
  RxAlignLeft,
  RxAlignRight,
  RxAlignTop,
  RxSpaceBetweenHorizontally,
  RxSpaceBetweenVertically,
  RxSpaceEvenlyHorizontally,
  RxSpaceEvenlyVertically,
} from 'react-icons/rx';
import { CustomToggleButton } from './CustomToggleButton';
import { useTranslation } from 'next-i18next';

/**
 * Props for the JustifyContentFlexButtonGroupField component.
 *
 * @typeParam FormValues The type of the form values object.
 */
export interface JustifyContentFlexButtonGroupFieldProps<
  FormValues extends FieldValues,
> {
  direction: string;
  control: Control<FormValues>;
}

/**
 * Renders a toggle button group for selecting justify content options for a flex container.
 *
 * @typeParam FormValues - The type of form values.
 *
 * @param {object} props - The component props.
 * @param props.direction - The direction of the flex container.
 * @param {import('react-hook-form').Control<FormValues>} props.control - The control object from react-hook-form.
 *
 * @returns  - The component JSX.
 */
export function JustifyContentFlexButtonGroupField<
  FormValues extends FieldValues,
>({ direction, control }: JustifyContentFlexButtonGroupFieldProps<FormValues>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'sx.justifyContent' as Path<FormValues>,
    control,
  });
  const { t } = useTranslation();
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography gutterBottom>
        {t('Justify Content')}: <em>{field.value}</em>
      </Typography>
      <ToggleButtonGroup
        value={field.value}
        onChange={(event, alignment) => {
          field.onChange(alignment || '');
        }}
        exclusive
        aria-label={t('flex alignment') || 'flex alignment'}
      >
        <CustomToggleButton value="center" aria-label={'center'}>
          {direction === 'column' ? (
            <RxAlignCenterVertically />
          ) : (
            <RxAlignCenterHorizontally />
          )}
        </CustomToggleButton>
        <CustomToggleButton value={'flex-start'} aria-label={'flex-start'}>
          {direction === 'column' ? <RxAlignTop /> : <RxAlignLeft />}
        </CustomToggleButton>
        <CustomToggleButton value="flex-end" aria-label={'flex-end'}>
          {direction === 'column' ? <RxAlignBottom /> : <RxAlignRight />}
        </CustomToggleButton>
        <CustomToggleButton value="space-between" aria-label={'space-between'}>
          {direction === 'column' ? (
            <RxSpaceBetweenVertically />
          ) : (
            <RxSpaceBetweenHorizontally />
          )}
        </CustomToggleButton>
        <CustomToggleButton value="space-around" aria-label={'space-around'}>
          {direction === 'column' ? (
            <BsDistributeVertical />
          ) : (
            <BsDistributeHorizontal />
          )}
        </CustomToggleButton>
        <CustomToggleButton value="space-evenly" aria-label={'space-evenly'}>
          {direction === 'column' ? (
            <RxSpaceEvenlyVertically />
          ) : (
            <RxSpaceEvenlyHorizontally />
          )}
        </CustomToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="error">
        {error?.message}
      </Typography>
    </Box>
  );
}
