import Box, { BoxProps } from '@mui/material/Box';

/**
 * Props for the VideoAreaRow component.
 */
export interface VideoAreaRowProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * A layout component that displays its children in a row, with a 32:9 aspect ratio.
 * @param {VideoAreaRowProps} props - The props for the VideoAreaRow component.
 * @param {ReactNode} props.children - The child elements to be displayed in the row.
 * @param {SxStyleProp} props.sx - The custom styles to apply to the component.
 * @returns  - The VideoAreaRow component.
 */
export function VideoAreaRow({ children, sx }: VideoAreaRowProps) {
  if (!children) return null;
  return (
    <Box
      sx={[
        {
          gap: [0, 2],
          aspectRatio: '32/9',
          display: 'flex',
          flexDirection: ['column', 'row'],
          '> *': { flex: 1 },
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ...(sx as any),
        },
      ]}
    >
      {children}
    </Box>
  );
}
