import { LayoutProps } from '@livekatsomo/models';
import Box, { BoxProps } from '@mui/material/Box';

/**
 * A layout component that displays a video in the center and two sidebars on the left and right.
 * @param children The content to be displayed inside the layout.
 * @param sx The inline CSS styles.
 * @param props The BoxProps and LayoutProps to be passed down to the Box component.
 * @returns A JSX element that displays the video layout with main and sidebars.
 */
export function VideoLayoutWithMainAndSide({
  children,
  sx,
  ...props
}: BoxProps & LayoutProps) {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        aspectRatio: ['16/9', '24/9'],
        justifyContent: 'center',
        position: 'relative',
        gap: 1,
        zIndex: 0,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
