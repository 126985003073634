import { LayoutProps } from '@livekatsomo/models';
import Container, { ContainerProps } from '@mui/material/Container';

/**
 * A layout container component that restricts the maximum width of its content.
 *
 * @param children - The content to render inside the container.
 * @param sx - The inline style object to apply to the container.
 * @param maxWidth - The maximum width of the container. Defaults to 'xl'.
 * @param props - Additional props to pass to the underlying `Container` component.
 * @returns The rendered `Container` component.
 */
export function MaxWidthLayoutContainer({
  children,
  sx,
  maxWidth,
  ...props
}: ContainerProps & LayoutProps) {
  const sxProps = {
    ...sx,
  };
  return (
    <Container sx={sxProps} maxWidth={maxWidth || 'xl'} {...props}>
      {children}
    </Container>
  );
}
