import { DevTool } from '@hookform/devtools';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  collectionRendererSchema,
  configurationTypeSchema,
} from '@livekatsomo/models';
import {
  RadioField,
  SelectField,
} from '@livekatsomo/web/ui-components/react-hook-form-components';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  EditorFormProps,
  LayoutEditorAddFormValues,
  AddFormSchema,
} from './LayoutEditorAddItemDialog';

/**
 * Renders a form for adding a layout item, either a container or a component.
 * @returns The JSX element representing the form.
 */
export function AddItemForm({
  collectionId,
  components,
  onSubmit,
  onClose,
}: EditorFormProps) {
  const { t } = useTranslation();
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<LayoutEditorAddFormValues>({
    defaultValues: {
      type: collectionId === 'baseLayout' ? 'area' : 'component',
      componentName: '',
      collectionContainer: '',
    },
    resolver: zodResolver(AddFormSchema),
  });

  const type = watch('type');

  const handleAddItem = (values: LayoutEditorAddFormValues) => {
    const parsedValues = AddFormSchema.parse(values);
    return onSubmit(collectionId, parsedValues);
  };

  return (
    <form onSubmit={handleSubmit(handleAddItem)}>
      <DialogTitle>Add Layout Item</DialogTitle>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        <DialogContentText>
          {t('Add container or component.')}
        </DialogContentText>

        {collectionId !== 'baseLayout' ? (
          <RadioField
            name="type"
            defaultValue="component"
            control={control}
            label={t('type')}
            options={
              ['component', 'collection'] as z.infer<
                typeof configurationTypeSchema
              >[]
            }
          />
        ) : null}
        {type === 'component' ? (
          <SelectField
            name="componentName"
            control={control}
            options={components}
            label={'Component'}
          />
        ) : (
          <SelectField
            name="collectionContainer"
            control={control}
            options={collectionRendererSchema.options}
            optionSelector={(option) => option || ''}
            optionViewer={(option) => option || 'none'}
            label={'Container'}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button type="submit" disabled={!isDirty || !isValid}>
          {t('Add')}
        </Button>
        <Button onClick={onClose}>{t('Cancel')}</Button>
      </DialogActions>
      <DevTool control={control} />
    </form>
  );
}
