/**
 * @file This file exports the LayoutEditorAddItemDialog component and its dependencies.
 * @module LayoutEditorAddItemDialog
 */

import {
  collectionRendererSchema,
  configurationTypeSchema,
} from '@livekatsomo/models';
import Dialog from '@mui/material/Dialog';
import { z } from 'zod';
import { AddItemForm } from './AddItemForm';

const emptyStringSchema = z.literal('');

/**
 * A discriminated union schema for the form used to add items to the layout editor.
 *
 * @remarks
 * This schema defines the shape of the form data used to add items to the layout editor.
 * It is a discriminated union schema, meaning that the `type` property is used to determine
 * which shape the data takes.
 */
export const AddFormSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal(configurationTypeSchema.Enum.component),
    componentName: z.string(),
    collectionContainer: emptyStringSchema,
  }),
  z.object({
    type: z.literal(configurationTypeSchema.Enum.collection),
    collectionContainer: collectionRendererSchema,
  }),
  z.object({
    type: z.literal(configurationTypeSchema.Enum.area),
    collectionContainer: collectionRendererSchema,
  }),
]);

export type LayoutEditorAddFormValues = {
  type: z.infer<typeof configurationTypeSchema>;
  componentName?: string;
  collectionContainer: z.infer<typeof collectionRendererSchema> | '';
};

/**
 * Props for the LayoutEditorAddItemDialog component.
 */
export type LayoutEditorAddItemDialogProps = Omit<
  EditorFormProps,
  'collectionId'
> & {
  collectionId: string | null;
};

/**
 * A dialog component that displays a form for adding items to the layout editor.
 *
 * @param {LayoutEditorAddItemDialogProps} props - The props for the component.
 * @returns  The rendered component.
 */
export function LayoutEditorAddItemDialog({
  collectionId,
  ...props
}: LayoutEditorAddItemDialogProps) {
  const open = Boolean(collectionId);

  return (
    <Dialog open={open}>
      {collectionId ? (
        <AddItemForm {...props} collectionId={collectionId} />
      ) : null}
    </Dialog>
  );
}

/**
 * Props for the editor form used in the LayoutEditorAddItemDialog component.
 */
export interface EditorFormProps {
  /**
   * The ID of the collection to which the item will be added.
   */
  collectionId: string;
  /**
   * The list of available components to choose from.
   */
  components: string[];
  /**
   * The function to call when the form is submitted.
   *
   * @param collectionId - The ID of the collection to which the item will be added.
   * @param values - The values for the layout item.
   * @returns
   */
  onSubmit: (
    collectionId: string,
    values: z.infer<typeof AddFormSchema>,
  ) => void;
  /**
   * The function to call when the form is closed.
   */
  onClose: () => void;
}
