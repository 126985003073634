import { LayoutProps } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { forwardRef } from 'react';

export type LayoutContainerProps = {
  id: string;
  items: string[];
} & LayoutProps;

/**
 * A container component that provides layout and styling options.
 * @param boxed - Whether to use a boxed layout.
 * @param elevation - The elevation level of the container.
 * @param square - Whether to use a square layout.
 * @param maxWidth - The maximum width of the container.
 * @param children - The child elements to be rendered inside the container.
 * @param sx - The custom styling properties.
 * @param className - The custom CSS class name.
 * @param stickyLastChild - Whether to make the last child element sticky.
 * @param id - The ID of the container.
 * @param htmlProps - The HTML props to be passed to the container.
 * @returns The rendered container component.
 */
export const LayoutContainer = forwardRef(function LayoutContainer(
  {
    boxed,
    elevation,
    square,
    maxWidth,
    children,
    sx,
    className,
    stickyLastChild,
    id,
    ...htmlProps
  }: LayoutContainerProps,
  ref,
) {
  const sxProps = {
    position: 'relative',
    border: 0,
    // minWidth: 0 is needed for swiper to work. Without it the swiper keeps growing indefinitely.
    minWidth: 0,
    transition: 'border 0.5s, padding 0.5s, margin 0.5s',
    ...(stickyLastChild
      ? {
          '& > *:last-child': {
            position: 'sticky',
            top: 0,
          },
        }
      : {}),
    // Add minWidth: 0 to all children to prevent the swiper from overflowing the container
    '& > div': {
      minWidth: 0,
    },
    ...sx,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  const props = {
    ref,
    component: boxed ? Paper : Box,
    ...(boxed && { elevation, square }),
    className,
    sx: sxProps,
    ...htmlProps,
    children,
  };

  return maxWidth ? (
    <Container maxWidth={maxWidth} id={id} {...props} />
  ) : (
    <Box {...props} />
  );
});
