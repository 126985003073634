import Box from '@mui/material/Box';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { BsDistributeHorizontal } from 'react-icons/bs';
import {
  RxAlignCenterHorizontally,
  RxAlignLeft,
  RxAlignRight,
  RxSpaceBetweenHorizontally,
  RxSpaceEvenlyHorizontally,
} from 'react-icons/rx';
import { CustomToggleButton } from './CustomToggleButton';
import { useTranslation } from 'next-i18next';

/**
 * Props for the JustifyContentGridButtonGroupField component.
 * @typeParam FormValues The type of the form values object.
 */
export interface JustifyContentGridButtonGroupFieldProps<
  FormValues extends FieldValues,
> {
  control: Control<FormValues>;
}

/**
 * Renders a toggle button group for selecting justify content options for a grid layout.
 *
 * @typeParam FormValues - The type of form values.
 *
 * @param props - The component props.
 * @param {Control<FormValues>} props.control - The react-hook-form control object.
 *
 * @returns  - The component JSX.
 */
export function JustifyContentGridButtonGroupField<
  FormValues extends FieldValues,
>({ control }: JustifyContentGridButtonGroupFieldProps<FormValues>) {
  const { t } = useTranslation();
  const {
    field,
    fieldState: { error },
  } = useController({
    name: 'sx.justifyContent' as Path<FormValues>,
    control,
  });
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography gutterBottom>
        {t('Justify Content')}: <em>{field.value}</em>
      </Typography>
      <ToggleButtonGroup
        value={field.value}
        onChange={(event, alignment) => {
          field.onChange(alignment || '');
        }}
        exclusive
        aria-label={t('grid justify content') || 'grid justify content'}
      >
        <CustomToggleButton value="center" aria-label={'center'}>
          <RxAlignCenterHorizontally />
        </CustomToggleButton>
        <CustomToggleButton value="start" aria-label={'start'}>
          <RxAlignLeft />
        </CustomToggleButton>
        <CustomToggleButton value="end" aria-label={'end'}>
          <RxAlignRight />
        </CustomToggleButton>
        <CustomToggleButton value="space-between" aria-label={'space-between'}>
          <RxSpaceBetweenHorizontally />
        </CustomToggleButton>
        <CustomToggleButton value="space-around" aria-label={'space-around'}>
          <BsDistributeHorizontal />
        </CustomToggleButton>
        <CustomToggleButton value="space-evenly" aria-label={'space-evenly'}>
          <RxSpaceEvenlyHorizontally />
        </CustomToggleButton>
      </ToggleButtonGroup>
      <Typography variant="caption" color="error">
        {error?.message}
      </Typography>
    </Box>
  );
}
