import Container, { ContainerProps } from '@mui/material/Container';

/**
 * A layout container component for the home page.
 * @param props The props for the container component.
 * @returns The home page layout container.
 */
export function HomePageLayoutContainer(props: ContainerProps) {
  return (
    <Container
      maxWidth="lg"
      sx={{
        flex: '1',
        display: 'flex',
        flexDirection: 'column',
        my: 4,
        gap: 4,
        alignItems: 'center',
      }}
      component="div"
      {...props}
    />
  );
}
