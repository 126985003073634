import Container, { ContainerProps } from '@mui/material/Container';

/**
 * Props for the AdminGridContainer component.
 */
export interface AdminGridContainerProps extends ContainerProps {
  children: React.ReactNode;
}

/**
 * Renders a container with a grid layout for admin UI components.
 * @param {AdminGridContainerProps} props - The props for the AdminGridContainer component.
 * @param props.sx - The sx prop from Material UI's Box component.
 * @returns The rendered AdminGridContainer component.
 */
export function AdminGridContainer({ sx, ...props }: AdminGridContainerProps) {
  return (
    <Container
      disableGutters
      sx={{
        flex: '1',
        my: 4,
        display: 'grid',
        gap: 2,
        overflowX: 'clip',
        gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))',
        '& .MuiCard-root': {
          minWidth: 275,
          display: 'flex',
          flexDirection: 'column',
        },
        '& .MuiCardContent-root': {
          flex: '1',
        },
      }}
      {...props}
    />
  );
}

export default AdminGridContainer;
