import React, { ReactNode } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { z } from 'zod';
import { gridTemplateColumnsSchema } from '@livekatsomo/models';
import { useTranslation } from 'next-i18next';
import { GridTemplateForBreakpointField } from './GridTemplateForBreakpointField';

/**
 * The available breakpoints for the grid template.
 */
const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'] as const;

export type Breakpoint = (typeof breakpoints)[number];

/**
 * Represents the configuration for grid template columns at different breakpoints.
 */
export type BreakpointGridTemplateConfig = z.infer<
  typeof gridTemplateColumnsSchema
>;

/**
 * Configuration object for a breakpoint's grid template.
 * Excludes the breakpoint's name (string) from the configuration.
 */
type BreakpointObjectConfig = Exclude<BreakpointGridTemplateConfig, string>;

/**
 * Parses a grid template string and returns an array of numbers representing the columns.
 * @param template - The grid template string to parse.
 * @returns An array of numbers representing the columns.
 */
export function parseGridTemplateColumns(template: string): number[] {
  const parts = template.split(' ');
  const fractions = parts.map((part) => parseInt(part, 10));
  return fractions;
}

/**
 * Converts an array of numbers representing grid template columns to a string with "fr" units.
 * @param columns - An array of numbers representing the width of each column in the grid.
 * @returns A string with "fr" units representing the grid template columns.
 */
export function stringifyGridTemplateColumns(columns: number[]): string {
  const fractions = columns.map((column) => `${column}fr`);
  return fractions.join(' ');
}

/**
 * Determines if the given value is a BreakpointObjectConfig.
 * @param value The value to check.
 * @returns True if the value is a BreakpointObjectConfig, false otherwise.
 */
function isBreakpointConfig(
  value: BreakpointGridTemplateConfig,
): value is BreakpointObjectConfig {
  return typeof value === 'object';
}

/**
 * Props for the GridTemplateField component.
 * @typeParam FormValues The type of the form values object.
 */
export interface GridTemplateFieldProps<
  FormValues extends FieldValues & {
    sx: {
      gridTemplateColumns?: BreakpointGridTemplateConfig;
    };
  },
> {
  control: Control<FormValues>;
}

/**
 * A form field component for editing grid template columns.
 * @typeParam FormValues The type of form values.
 * @param props The component props.
 * @returns The JSX element.
 */
export function GridTemplateField<
  FormValues extends FieldValues & {
    sx: {
      gridTemplateColumns?: BreakpointGridTemplateConfig;
    };
  },
>({ control }: GridTemplateFieldProps<FormValues>) {
  const { t } = useTranslation();
  const { field } = useController({
    name: 'sx.gridTemplateColumns' as Path<FormValues>,
    control,
  });

  const input = field.value as BreakpointGridTemplateConfig;
  const selectRef = React.useRef<HTMLSelectElement>(null);

  const handleAddNewBreakpointConfig = (
    event: SelectChangeEvent<Breakpoint>,
  ) => {
    const breakpoint = event.target.value;
    if (isBreakpointConfig(input)) {
      field.onChange({
        ...input,
        [breakpoint]: '1fr',
      });
    } else {
      field.onChange({
        xs: input,
        [breakpoint]: '1fr',
      });
    }
    if (selectRef.current) selectRef.current.value = '';
    selectRef.current?.blur();
  };

  const handleDeleteBreakpointConfig = (breakpoint: Breakpoint) => {
    if (isBreakpointConfig(input)) {
      const { [breakpoint]: _, ...rest } = input;
      field.onChange(rest);
    }
  };

  let children: ReactNode;

  if (isBreakpointConfig(input)) {
    const breakpointConfigs = Object.entries(input).sort(
      ([a], [b]) =>
        breakpoints.indexOf(a as Breakpoint) -
        breakpoints.indexOf(b as Breakpoint),
    );
    const availableBreakpoints = breakpoints.filter(
      (breakpoint) => !(breakpoint in input),
    );
    children = (
      <>
        {breakpointConfigs.map(([breakpoint, value]) => (
          <GridTemplateForBreakpointField
            key={breakpoint}
            value={value}
            breakpoint={breakpoint as Breakpoint}
            onDeleteBreakpointConfig={handleDeleteBreakpointConfig}
            setValue={(value) => {
              field.onChange({
                ...input,
                [breakpoint]: value,
              });
            }}
          />
        ))}
        {availableBreakpoints.length ? (
          <FormControl fullWidth margin="normal">
            <InputLabel id="select-new-breakpoint-label">
              {t('Add new breakpoint')}
            </InputLabel>
            <Select
              labelId="select-new-breakpoint-label"
              id="select-new-breakpoint"
              value={''}
              label={t('Add new breakpoint')}
              onChange={handleAddNewBreakpointConfig}
            >
              {availableBreakpoints.map((breakpoint) => (
                <MenuItem key={breakpoint} value={breakpoint}>
                  {breakpoint.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
      </>
    );
  } else {
    children = (
      <FormControl fullWidth margin="normal">
        <InputLabel id="select-new-breakpoint-label">
          {t('Add new breakpoint')}
        </InputLabel>
        <Select
          labelId="select-new-breakpoint-label"
          id="select-new-breakpoint"
          value={''}
          label={t('Add new breakpoint')}
          onChange={handleAddNewBreakpointConfig}
        >
          {breakpoints.map((breakpoint) => (
            <MenuItem key={breakpoint} value={breakpoint}>
              {breakpoint.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <>
      <Typography gutterBottom>{t('Grid Template Columns')}</Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        {children}
      </Box>
    </>
  );
}

export default GridTemplateField;
