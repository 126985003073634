import { useContext } from 'react';
import { EditContext } from './EditContext';

/**
 * Custom hook that returns the EditContext from the nearest EditContextProvider in the component tree.
 * @throws Error if used outside of an EditContextProvider.
 * @returns EditContext object containing the current layout being edited and functions to update it.
 */
export function useEditLayout() {
  const context = useContext(EditContext);
  if (!context) {
    throw new Error('useEditLayout must be used within EditContextProvider');
  }
  return context;
}
