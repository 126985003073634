import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { useTranslation } from 'next-i18next';
import {
  Breakpoint,
  parseGridTemplateColumns,
  stringifyGridTemplateColumns,
} from './GridTemplateField';

/**
 * Props for the GridTemplateForBreakpointField component.
 */
export interface GridTemplateForBreakpointFieldProps {
  value: string;
  breakpoint: Breakpoint;
  setValue: (value: string) => void;
  onDeleteBreakpointConfig: (breakpoint: Breakpoint) => void;
}

/**
 * A component that renders a form field for editing grid template columns for a specific breakpoint.
 * @param value - The current value of the grid template columns.
 * @param breakpoint - The breakpoint for which the grid template columns are being edited.
 * @param setValue - A function to update the value of the grid template columns.
 * @param onDeleteBreakpointConfig - A function to delete the breakpoint configuration.
 * @returns A React component that renders a form field for editing grid template columns for a specific breakpoint.
 */
export function GridTemplateForBreakpointField({
  value,
  breakpoint,
  setValue,
  onDeleteBreakpointConfig,
}: GridTemplateForBreakpointFieldProps) {
  const { t } = useTranslation();
  const gridTemplateColumns = value ? parseGridTemplateColumns(value) : [1];

  const submit = (value: number[]) => {
    const newColumns = stringifyGridTemplateColumns(value);
    setValue(newColumns);
  };

  const handleAddColumn = () => {
    const columnArray = Array.isArray(gridTemplateColumns)
      ? [...gridTemplateColumns, 1]
      : [gridTemplateColumns, 1];
    submit(columnArray);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const { value } = event.target;
    const columnArray = Array.isArray(gridTemplateColumns)
      ? [...gridTemplateColumns]
      : [gridTemplateColumns];
    columnArray[index] = +value;
    submit(columnArray);
  };

  const deleteColumn = (index: number) => {
    const columnArray = Array.isArray(gridTemplateColumns)
      ? [...gridTemplateColumns]
      : [gridTemplateColumns];
    columnArray.splice(index, 1);
    submit(columnArray);
  };

  return (
    <Box
      sx={{
        border: 1,
        borderColor: (theme) => theme.palette.divider,
        p: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography>
            {t('Column sizes for {{breakpoint}} and up', {
              breakpoint: breakpoint,
            })}
          </Typography>
          <Typography variant="caption">
            {t('Size as a fraction of available space')}
          </Typography>
        </Box>
        {breakpoint !== 'xs' ? (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <IconButton
              aria-label={t('delete column') || 'delete column'}
              onClick={() => onDeleteBreakpointConfig(breakpoint)}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : null}
      </Box>
      {gridTemplateColumns.map((column, index, data) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={10} sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              sx={{
                width: '80px',
              }}
              name={`gridTemplateColumns-${index}`}
              label={`Column-${index + 1}`}
              value={column}
              onChange={(event) => handleChange(event, index)}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{`/ ${data.reduce(
                    (acc, cur) => acc + cur,
                    0,
                  )}`}</InputAdornment>
                ),
              }}
            />
            <IconButton
              aria-label={t('delete column') || 'delete column'}
              onClick={() => deleteColumn(index)}
              edge="end"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button onClick={handleAddColumn}>{t('Add column')}</Button>
    </Box>
  );
}
