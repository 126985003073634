import Box from '@mui/material/Box';

/**
 * Props for the BottomAreaContainer component.
 */
export interface BottomAreaContainerProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * Renders a bottom area container with the provided children.
 * @param {BottomAreaContainerProps} props - The props for the BottomArea component.
 * @param {ReactNode} props.children - The children to be rendered inside the bottom area container.
 * @returns The JSX element representing the bottom area container.
 */
export function BottomArea({ children }: BottomAreaContainerProps) {
  if (!children) return null;
  return (
    <Box
      // maxWidth="xl"
      sx={[
        {
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        },
      ]}
    >
      {children}
    </Box>
  );
}

export default BottomArea;
