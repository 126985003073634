/**
 * @file This file exports a component that renders a form for editing a layout item's configuration.
 * @module LayoutEditorEditItemDrawer
 */

import { zodResolver } from '@hookform/resolvers/zod';
import {
  AreaConfiguration,
  CollectionConfiguration,
  CollectionsConfiguration,
  ComponentConfiguration,
  componentConfigurationSchema,
} from '@livekatsomo/models';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { removeUndefinedAndEmptyString } from '@livekatsomo/web/utils';
import {
  EditFormSchema,
  EditLayoutItemForm,
  LayoutEditFormValues,
} from './EditItemForm';
import { useTranslation } from 'next-i18next';

/**
 * Props for the LayoutEditorEditItemDrawer component.
 */
export interface LayoutEditorEditItemDrawerProps {
  /**
   * The item being edited.
   */
  item: {
    id: string;
    configuration:
      | CollectionConfiguration
      | ComponentConfiguration
      | AreaConfiguration;
  };
  /**
   * The function to call when the user submits the form.
   * @param id - The id of the item being edited.
   * @param configuration - The updated configuration of the item being edited.
   */
  onSubmit: (
    id: keyof CollectionsConfiguration,
    configuration: Partial<
      CollectionConfiguration | ComponentConfiguration | AreaConfiguration
    >,
  ) => void;
  /**
   * The function to call when the user cancels the form.
   */
  onCancel: () => void;
  /**
   * The function to call when the user closes the form.
   */
  onClose: () => void;
}

/**
 * A component that renders a form for editing a layout item's configuration.
 */
export function LayoutItemEditor({
  item,
  onSubmit,
  onCancel,
  onClose,
}: LayoutEditorEditItemDrawerProps) {
  const { t } = useTranslation();
  const { id, configuration } = item;
  const {
    control,
    handleSubmit,
    formState: { isValidating, isValid, isSubmitting },
    watch,
  } = useForm<LayoutEditFormValues>({
    mode: 'all',
    resolver: async (data, context, options) => {
      console.log('resolver', data, context, options);
      const validationStatus = await zodResolver(EditFormSchema)(
        data,
        context,
        options,
      );
      console.log('validationStatus', validationStatus);
      return validationStatus;
    },
    defaultValues: {
      boxed: configuration.boxed || false,
      square: configuration.square || false,
      maxWidth: ('maxWidth' in configuration && configuration.maxWidth) || '',
      elevation: configuration.elevation || '',
      aspectRatio:
        ('aspectRatio' in configuration && configuration.aspectRatio) || '',
      sx: {
        padding: configuration.sx?.padding || '',
        gap: configuration.sx?.gap ?? '',
        display: configuration.sx?.display ?? '',
        flexDirection: configuration.sx?.flexDirection ?? '',
        alignItems: configuration.sx?.alignItems ?? '',
        justifyContent: configuration.sx?.justifyContent ?? '',
        gridTemplateColumns: configuration.sx?.gridTemplateColumns ?? '',
      },
      maxChildCount: '',
      showPlaceholderInEmptySlots: true,
    },
  });

  const save = useCallback(
    (values: LayoutEditFormValues) => {
      console.log('calling save', values);
      if (!configuration || isValidating || isSubmitting || !isValid) {
        return;
      }
      const parsedValues = EditFormSchema.parse(values);

      if (configuration.type === 'component') {
        const { boxed, square, sx } = parsedValues;

        const componentContainer =
          boxed || square || sx.padding ? 'layout' : 'component';
        const componentConfiguration =
          componentContainer === 'layout'
            ? {
                componentContainer,
                boxed: parsedValues.boxed,
                elevation: parsedValues.elevation || undefined,
                square: parsedValues.square,
                sx: {
                  padding: parsedValues.sx.padding || undefined,
                },
                componentName: configuration.componentName,
                type: 'component',
              }
            : {
                componentContainer,
                componentName: configuration.componentName,
                type: 'component',
                boxed: false,
                square: false,
                sx: {},
              };
        const val = componentConfigurationSchema.parse(componentConfiguration);
        const cleaned = removeUndefinedAndEmptyString(val);
        onSubmit(id, cleaned);
        return;
      }

      if (
        configuration.type === 'collection' ||
        configuration.type === 'area'
      ) {
        const collectionConfiguration:
          | Partial<CollectionConfiguration>
          | Partial<AreaConfiguration> = {
          boxed: parsedValues.boxed,
          square: parsedValues.square,
          maxWidth: parsedValues.maxWidth || undefined,
          elevation: parsedValues.elevation || undefined,
          aspectRatio: parsedValues.aspectRatio || undefined,
          sx: {
            padding: parsedValues.sx.padding || undefined,
            gap: parsedValues.sx.gap || undefined,
            display: parsedValues.sx.display || undefined,
            flexDirection:
              parsedValues.sx.display === 'flex'
                ? parsedValues.sx.flexDirection || undefined
                : undefined,
            alignItems:
              parsedValues.sx.display && parsedValues.sx.alignItems
                ? parsedValues.sx.alignItems
                : undefined,
            justifyContent:
              parsedValues.sx.display && parsedValues.sx.justifyContent
                ? parsedValues.sx.justifyContent
                : undefined,
            gridTemplateColumns:
              parsedValues.sx.display === 'grid' &&
              parsedValues.sx.gridTemplateColumns
                ? parsedValues.sx.gridTemplateColumns
                : undefined,
          },
        };
        const cleaned = removeUndefinedAndEmptyString(collectionConfiguration);
        onSubmit(id, cleaned);
        return;
      }
    },
    [configuration, isValidating, isSubmitting, isValid, onSubmit, id],
  );

  useEffect(() => {
    const subscription = watch((data) => {
      console.log({ data });
      if (data) handleSubmit(save)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, save, watch]);

  if (!item) return null;

  return (
    <>
      <Typography variant="h6">
        {t('Edit')} {configuration.type}
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
        {id && configuration ? (
          <EditLayoutItemForm
            control={control}
            watch={watch}
            item={configuration}
            onSubmit={handleSubmit(save)}
          />
        ) : null}
        <Button onClick={onClose}>{t('Save')}</Button>
        <Button onClick={onCancel}>{t('Cancel')}</Button>
      </Box>
    </>
  );
}

export default LayoutItemEditor;
