import { RenderingConfiguration } from '@livekatsomo/models';
import { LayoutContainer } from './layout-containers/LayoutContainer';
import { LayoutWithCenterSlider } from './layout-containers/LayoutWithCenterSlider';

/**
 * The default rendering configuration for the UI components library.
 * It includes the default layout and the layout with a center slider.
 */
export const defaultRenderingConfiguration: RenderingConfiguration = {
  layout: LayoutContainer,
  layoutWithCenterSlider: LayoutWithCenterSlider,
};
