import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { LayoutProps } from '@livekatsomo/models';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactNode } from 'react';

/**
 * Props for the BaseContainer component.
 */
export interface BaseContainerProps extends LayoutProps {
  /**
   * Unique identifier for the container.
   */
  id: UniqueIdentifier;
  /**
   * The children to be rendered inside the container.
   */
  children: ReactNode;
  /**
   * The layout container to be used.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  LayoutContainer: OverridableComponent<any>;
  /**
   * Additional class name(s) for the container.
   */
  className?: string;
}

/**
 * Renders a base container with a given layout.
 * @param {BaseContainerProps} props - The props for the BaseContainer component.
 * @param props.id - The id of the container.
 * @param {React.ElementType} props.LayoutContainer - The layout container to use.
 * @returns  - The rendered BaseContainer component.
 */
export function BaseContainer({
  id,
  LayoutContainer,
  ...props
}: BaseContainerProps) {
  const { setNodeRef } = useDroppable({
    id,
  });

  return <LayoutContainer ref={setNodeRef} {...props} />;
}
