import {
  ComponentConfiguration,
  RenderingConfiguration,
} from '@livekatsomo/models';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { forwardRef, ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';

/**
 * Props for the RenderComponent component.
 */
export interface RenderComponentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The configuration for the component.
   */
  configuration: ComponentConfiguration;
  /**
   * The rendering configuration for the component.
   */
  renderingConfiguration: RenderingConfiguration;
  /**
   * The children of the component.
   */
  children: ReactNode;
  /**
   * The custom styles for the component.
   */
  sx?: SxProps;
  /**
   * The container component for the component.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentContainer?: OverridableComponent<any>;
}

/**
 * Renders a component with optional configuration and rendering configuration.
 * @param configuration - The configuration for the component.
 * @param renderingConfiguration - The rendering configuration for the component.
 * @param children - The child elements to be rendered inside the component.
 * @param componentContainer - The container element for the component.
 * @param props - Additional props to be spread on the container element.
 * @returns The rendered component.
 */
export const RenderComponent = forwardRef<HTMLDivElement, RenderComponentProps>(
  function (
    {
      configuration,
      renderingConfiguration,
      children,
      componentContainer: ComponentContainer,
      ...props
    },
    ref,
  ) {
    return ComponentContainer ? (
      <ComponentContainer ref={ref} className="component-container" {...props}>
        {children}
      </ComponentContainer>
    ) : (
      <div ref={ref} {...props}>
        {children}
      </div>
    );
  },
);

export default RenderComponent;
