import { LayoutOverlay } from './LayoutOverlay';
import {
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Placeholder } from '../layout-rendering/sortable/Placeholder';
import { LayoutMenuButton } from './LayoutMenuButton';
import { LayoutContainerProps } from './LayoutContainer';
import { ReactNode, useEffect, useState } from 'react';
import { CollectionConfiguration } from '@livekatsomo/models';
import Box from '@mui/material/Box';
import { animateLayoutChanges } from './animateLayoutChanges';

/**
 * A hook that returns a boolean indicating whether the component is mounted or not.
 * @returns A boolean indicating whether the component is mounted or not.
 */
function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}

/**
 * Determines if the layout is horizontal based on the provided styles.
 * @param sx - The styles to check for horizontal layout.
 * @returns A boolean indicating if the layout is horizontal.
 */
function isHorizontal(sx?: { flexDirection?: string; display?: string }) {
  return sx?.flexDirection === 'row' || sx?.display === 'grid';
}

/**
 * Props for the SortableCollectionWrapper component.
 */
export type SortableCollectionWrapperProps = LayoutContainerProps & {
  /**
   * The unique identifier for the collection.
   */
  id: string;
  /**
   * The configuration for the collection.
   */
  configuration: CollectionConfiguration;
  /**
   * The layout container component to use for rendering the collection.
   */
  LayoutContainer: React.ComponentType<LayoutContainerProps>;
} & CollectionConfiguration;

/**
 * A wrapper component for sortable collections.
 *
 * @param id - The unique identifier for the collection.
 * @param children - The child components to be rendered within the collection.
 * @param items - The items to be sorted within the collection.
 * @param LayoutContainer - The layout container component to be used for rendering the collection.
 * @param configuration - The configuration object for the collection.
 * @param collectionContainer - The container component for the collection.
 * @param props - Additional props to be passed to the component.
 * @returns A React component that renders a sortable collection.
 */
export function SortableCollectionWrapper({
  id,
  children,
  items,
  LayoutContainer,
  configuration,
  collectionContainer,
  ...props
}: SortableCollectionWrapperProps) {
  const isFull = Boolean(
    configuration.maxChildCount && items.length >= configuration.maxChildCount,
  );

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    setActivatorNodeRef,
  } = useSortable({
    id: id,
    animateLayoutChanges,
    disabled: isFull,
    data: {
      collectionId: id,
      configuration,
    },
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;
  const horizontal = isHorizontal(props.sx);

  let content: ReactNode;

  if (items.length === 0) {
    content = <Placeholder collectionId={id} />;
  } else {
    content = children;
  }

  if (
    configuration.maxChildCount &&
    Array.isArray(children) &&
    children.length < configuration.maxChildCount
  ) {
    content = [...children, <Placeholder collectionId={id} />];
  }

  return (
    <Box
      ref={setNodeRef}
      {...attributes}
      className="sortable-collection-wrapper"
      style={style}
      id={id}
      sx={{
        transition: 'opacity 3s',
        opacity: isDragging ? 0.5 : 1,
        ...(mountedWhileDragging && {
          // Fade in
          animation: 'fadeIn 0.3s',
        }),
        position: 'relative',
        '&:hover': {
          '& > .layout-overlay,& > .layout-menu-button': {
            opacity: 1,
            visibility: 'visible',
          },
          '& > .layout-menu-button': {
            pointerEvents: 'all',
            opacity: 1,
            visibility: 'visible',
          },
          '&.add-new': {
            display: 'flex',
          },

          '& > .layout-container': {
            border: '16px solid transparent',
          },
        },
        '&:hover:has(.sortable-collection-wrapper:hover, .sortable-component-wrapper:hover)':
          {
            '& > .layout-menu-button': {
              pointerEvents: 'none',
              opacity: 0,
              visibility: 'hidden',
            },
          },
      }}
    >
      <SortableContext
        id={id}
        items={items}
        strategy={
          horizontal
            ? horizontalListSortingStrategy
            : verticalListSortingStrategy
        }
      >
        <LayoutOverlay type={configuration.type} />
        <LayoutMenuButton
          type={configuration.type}
          id={id}
          setActivatorNodeRef={setActivatorNodeRef}
          listeners={listeners}
          disableAdd={isFull}
        />
        <LayoutContainer
          className="layout-container"
          id={id}
          items={items}
          {...props}
        >
          {content}
        </LayoutContainer>
      </SortableContext>
    </Box>
  );
}
