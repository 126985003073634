import { closestCenter, CollisionDetection } from '@dnd-kit/core';
import { LayoutConfiguration } from '@livekatsomo/models';

/**
 * Finds the closest container to the active draggable element from a list of droppable containers,
 * based on their center positions.
 *
 * @param config - The layout configuration object.
 * @param args - The arguments object for the collision detection function.
 * @returns The closest container to the active draggable element.
 * @throws An error if the parent container is not found or is not a collection.
 */
export function getClosestContainerFromParent(
  config: LayoutConfiguration,
  args: Parameters<CollisionDetection>[0],
) {
  const parentContainer = args.active.data?.current?.sortable.containerId as
    | string
    | undefined;
  if (!parentContainer) {
    throw new Error('Parent container not found');
  }
  const parentConfig = config.collections[parentContainer];
  if (parentConfig.type === 'component') {
    throw new Error('Parent container is not a collection');
  }
  const parentItems = parentConfig.items;

  const droppables = args.droppableContainers.filter((container) =>
    parentItems.includes(container.id as string),
  );
  const closest = closestCenter({
    ...args,
    droppableContainers: droppables,
  });
  return closest;
}
