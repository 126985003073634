import Box, { BoxProps } from '@mui/material/Box';

/**
 * Props for the VideoArea component.
 */
export interface VideoAreaProps extends BoxProps {
  children: React.ReactNode | React.ReactNode[];
}

/**
 * A layout component that displays video content.
 *
 * @param {VideoAreaProps} props - The props for the VideoArea component.
 * @param {ReactNode} props.children - The child elements to be rendered within the VideoArea.
 * @returns  - The rendered VideoArea component.
 */
export function VideoArea({ children }: VideoAreaProps) {
  if (!children) return null;
  return (
    <Box
      sx={[
        {
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
        },
      ]}
    >
      {children}
    </Box>
  );
}
