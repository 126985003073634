import {
  LayoutConfiguration,
  RenderingConfiguration,
} from '@livekatsomo/models';
import { ComponentProps, forwardRef } from 'react';
import { RenderItem } from './RenderArea';

/**
 * Props for the RenderLayout component.
 */
export interface RenderLayoutProps
  extends Omit<ComponentProps<typeof RenderItem>, 'configuration'> {
  /**
   * The layout configuration to be rendered.
   */
  layoutConfiguration: LayoutConfiguration;
  /**
   * The rendering configuration to be used.
   */
  renderingConfiguration: RenderingConfiguration;
}

/**
 * Renders a layout based on the provided layout and rendering configurations.
 * @returns  - The rendered layout.
 */
export const RenderLayout = forwardRef(function RenderLayout(
  {
    layoutConfiguration,
    renderingConfiguration,
    components,
  }: RenderLayoutProps,
  ref,
) {
  const { collectionContainer, items, ...props } =
    layoutConfiguration.collections.baseLayout;

  const itemsWithName = items.map((item: string) => ({
    id: item,
    configuration: layoutConfiguration.collections[item],
  }));

  const LayoutContainer = collectionContainer
    ? renderingConfiguration[collectionContainer]
    : // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (renderingConfiguration['layout'] as any);

  return (
    <LayoutContainer
      ref={ref}
      name="baseLayout"
      className="layout-container"
      {...props}
    >
      {itemsWithName.map(({ id, configuration }) => (
        <RenderItem
          key={id}
          configuration={configuration}
          renderingConfiguration={renderingConfiguration}
          layoutConfiguration={layoutConfiguration}
          id={id}
          components={components}
        />
      ))}
    </LayoutContainer>
  );
});

export default RenderLayout;
